import {ErrorMessage, Field, Form, Formik} from 'formik'
import toast from 'react-hot-toast'
import {KTSVG} from '../../../_metronic/helpers'
import {postRequest} from '../Helpers/Requests'
import {CarBrandValidation} from '../js/CarBrandValidation'

export default function AddCarBrand({getAllBrands}) {
  const initialValues = {
    brandName: '',
    image: '',
  }

  const handleSubmit = async (values, {resetForm}) => {
    const formData = new FormData()
    formData.append('image', values.image)
    formData.append('brandName', values.brandName)
    try {
      const res = await postRequest('/car-brand', formData)
      if (res && res.data) {
        toast.success(res.data.message)
        getAllBrands()
        resetForm()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response?.data?.message || 'An error occurred')
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='add_carBrand'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Add Car Brand</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={CarBrandValidation}
            >
              {({setFieldValue}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Brand Name</label>
                      <Field
                        placeholder='Brand Name'
                        name='brandName'
                        className='form-control'
                        type='text'
                      />
                      <ErrorMessage name='brandName' component='div' className='text-danger' />
                    </div>

                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Brand Logo</label>
                      <input
                        placeholder='Image'
                        type='file'
                        className='form-control'
                        onChange={(event: any) => {
                          setFieldValue('image', event.target.files[0])
                        }}
                      />
                      <ErrorMessage name='image' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='reset' className='btn btn-light-danger' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
