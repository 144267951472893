/* eslint-disable */

import React, {useEffect, useState} from 'react'
import GeneralDetail from './GeneralDetail'
import Amenities from './Amenities'
import {Formik, Form} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'
import {ChargeStationInitialValues} from '../Helpers/AllInitialValues'
import {getRequest, putRequest} from '../Helpers/Requests'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  operatorId: Yup.string().required('Operator is required'),
  stationName: Yup.string().required('Station name is required'),
  stationId: Yup.string().required('Station ID is required'),
  type: Yup.string().required('Type is required'),
  location: Yup.object().shape({
    latitude: Yup.string().required('Latitude is required'),
    longitude: Yup.string().required('Longitude is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    pincode: Yup.string().required('Pincode is required'),
  }),
})

const EditStationDetails = () => {
  const [openAmenities, setOpenAmenities] = useState(false)
  const [UpdateAmenities, setUpdateAmenities] = useState<any>([])
  const [initialValues, setInitialValues] = useState(ChargeStationInitialValues)
  const [openGallery,setOpenGallery]=useState(false)
  const navigate = useNavigate()
  const {pointId} = useParams()

  // function for handling amenities
  function AmenitiesHandler(item) {
    if (UpdateAmenities.includes(item)) {
      let Filtered = UpdateAmenities.filter((e) => {
        if (item !== e) return e
      })
      setUpdateAmenities(Filtered)
    } else {
      setUpdateAmenities([...UpdateAmenities, item])
    }
  }
  const GetUserObject = async () => {
    try {
      const response = await getRequest(`/hub/${pointId}`)
      if (response && response.data) {
        console.log(response.data)
        const fetchedData = {...response.data.data.hub}
        delete fetchedData.id
        setUpdateAmenities(fetchedData.amenities)
        const updatedInitialValues = {
          ...initialValues,
          ...fetchedData,
        }
        setInitialValues(updatedInitialValues)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // Submit handler function for put request
  const handleSubmit = async (values: any) => {
    console.log(values.location.city, 'city getting here')
    try {
      console.log(values)
      let UpdateValues = {...values, amenities: UpdateAmenities}
      const formData = new FormData()
      formData.append('location[address]', values.location.address)
      formData.append('location[country]', values.location.country)
      formData.append('location[latitude]', values.location.latitude)
      formData.append('location[longitude]', values.location.longitude)
      formData.append('location[pincode]', values.location.pincode)
      formData.append('location[state]', values.location.state)
      formData.append('location[city]', values.location.city)
      formData.append('operatorId', values.operatorId)
      formData.append('name', values.stationName)
      formData.append('type', values.type)


      formData.append('image', values.image)

      UpdateAmenities.forEach((amenity: any, index: any) => {
        formData.append(`amenities[${index}]`, amenity)
      })
      delete UpdateValues.image
      formData.forEach((value, key) => {
        console.log(key, value)
      })
      const res = await putRequest(`/hub/${pointId}`, formData)
      if (res && res.data) {
        toast.success('Data Updated SucessFully')
        setTimeout(() => {
          navigate('/Chargestations/Charge/Stations')
        }, 1000)
      }
    } catch (err) {
      toast.error('Invalid Data Check Data')
      console.log(err)
    }
  }

  useEffect(() => {
    GetUserObject()
  }, [])

  return (
    <div className='mt-n5'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>EDIT STATION</h3>
        </div>
        <div className='card-body mt-5'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-5'>
            <li className='nav-item'>
              <a
                className={`nav-link ${!openAmenities && 'active'}`}
                onClick={() => {setOpenGallery(false);setOpenAmenities(false)}}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
              >
                GENERAL DETAILS
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${openAmenities && 'active'}`}
                onClick={() => {setOpenGallery(false);setOpenAmenities(true)}}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
              >
                AMENITIES
              </a>
            </li>
            
          </ul>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({errors, values, setFieldValue, touched}) => (
              <Form>
                <div className='mt-15'>
                  <div className={`${openAmenities ? 'd-none' : 'd-block'}`}>
                    <GeneralDetail
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                      touched={touched}
                    />
                    <div className='d-flex justify-content-end'>
                      <div className='mb-10'>
                        <button
                          type='button'
                          className='btn btn-light-success'
                          onClick={() => setOpenAmenities(true)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${openAmenities ? 'd-block' : 'd-none'}`}>
                    <Amenities UpdateAmenities={AmenitiesHandler} Amenities={UpdateAmenities} />
                    <div className='d-flex justify-content-end'>
                      <div className='mb-10 me-5'>
                        <button
                          type='button'
                          onClick={() => setOpenAmenities(false)}
                          className='btn btn-light-danger'
                        >
                          Back
                        </button>
                      </div>
                      {/* <PopUpSumbit text={'Update'} /> */}
                      <div className='mb-10 me-5'>
                        <button type='submit' className='btn btn-light-success'>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditStationDetails
