export const getDateFormat = (date) => {
  return new Date(date)
    .toLocaleString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .replace(',', '')
}

export const getDateFormatWithSeconds = (date) => {
  return new Date(date)
    .toLocaleString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    })
    .replace(',', '')
}

export function formatDate(data) {
  let dateObj = new Date(data)
  let options: any = {day: '2-digit', month: 'short', year: 'numeric'}
  return dateObj.toLocaleDateString('en-IN', options)
}

export function formatTime(data) {
  let dateObj = new Date(data)
  let hours = dateObj.getHours()
  let minutes = String(dateObj.getMinutes()).padStart(2, '0')
  let period = hours >= 12 ? 'pm' : 'am'

  // Convert to 12-hour format
  hours = hours % 12 || 12 // The hour '0' should be '12'

  return `${String(hours).padStart(2, '0')}:${minutes} ${period}`
}
