import React, { useEffect, useState } from 'react'
import { getRequest } from '../../../../pages/Helpers/Requests'
import { KTIcon } from '../../../../../_metronic/helpers'
import EditUserStationPrice from '../../../../pages/Components/EditUserStationPrice'

export default function UserStation({ className, stationPrice, userId, userDetails }) {
  const [stations, setStations] = useState([])
  const [stationId, setStationId] = useState('')

  const getAllStations = async () => {
    try {
      const res = await getRequest('/charger')
      if (res) {
        setStations(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllStations()
  }, [])
  return (
    <div>
      <div className={`card ${className}`}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='min-w-210px ps-3'>Station Name</th>
                  <th className='min-w-210px'> Charger ID</th>
                  <th className='min-w-210px'>Connectors</th>
                  <th className='min-w-210px'>Connectors Price</th>
                  <th className='min-w-210px'>User Price</th>
                  <th className='min-w-210px'>Action</th>
                </tr>
              </thead>
              <tbody>
                {stations?.map((station: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td className='ms-5'>{station?.hub?.name}</td>
                      <td>{station?.stationId}</td>
                      <td>{station?.connectors?.length}</td>
                      <td>
                        {' '}
                        {station?.connectors
                          ?.map(
                            (item: any) =>
                              `connector ${item.connector_ID} -
                                                 ₹ ${item.Tariff?.fixedPrice?.price}`
                          )
                          .join(', ')}
                      </td>

                      <td>
                        {stationPrice?.some((item: any) => station?.id === item?.stationId)
                          ? stationPrice?.map((item: any) =>
                            station?.id === item?.stationId
                              ? ` ${item?.price ? `₹ ${item?.price}` : 'N/A'}`
                              : ''
                          )
                          : 'N/A'}
                      </td>
                      <td>
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                          data-bs-toggle='modal'
                          data-bs-target='#edit_station_user_price'
                          onClick={() => setStationId(station?.id)}
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <EditUserStationPrice UserID={userId} StationID={stationId} allSations={userDetails} />
        </div>
      </div>
    </div>
  )
}
