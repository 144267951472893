/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Link, useParams} from 'react-router-dom'
import {getRequest} from '../../../../app/pages/Helpers/Requests'
import {User} from '../../../../app/pages/Helpers/Interfaces'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className: string
}

const pagePermissions = {
  ViewOperator: 'ViewOperator',
  DeleteCmsUser: 'DeleteCmsUser',
  EditCmsUser: 'EditCmsUser',
}

const TablesWidget14: React.FC<Props> = ({className}) => {
  const [Operators, setOperators] = useState<User[]>([])
  const [value, setValues] = useState<User>()
  const {permissions} = useAuth()

  const getAllUsers = async () => {
    try {
      const res = await getRequest(`/cms/users/cms/cpo`)
      if (res && res.data) {
        setOperators(res.data.cmsUsers)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  function EmailHandler(id: any) {
    const EmailFliter = Operators.find((e: User) => e.id === id)
    setValues(EmailFliter)
  }

  useEffect(() => {
    getAllUsers()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Charge Point Operator</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-200px text-center '>Operator Name</th>
                <th className='min-w-200px text-center '>Email Id</th>
                <th className='min-w-200px text-center '>Mobile Number</th>
                <th className='min-w-200px text-center'>Status</th>
                <th className='min-w-200px text-center '>Group Type</th>
                {permissions.includes(pagePermissions.EditCmsUser) && (
                  <th className='min-w-200px text-center'>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {Operators?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td className='min-w-200px text-center '>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img
                            src={toAbsoluteUrl('/media/avatars/blank.png')}
                            className='h-75 align-self-end'
                            alt={item.OperatorName}
                          />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={'/OperatorInformation/' + item.id}
                            className='text-dark fw-bold text-hover-success mb-1 fs-6'
                          >
                            {item.OperatorName}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className='min-w-200px text-center '>
                      <span className='text-dark fw-bold  d-block mb-1 fs-6'>{item.email}</span>
                    </td>
                    <td className='min-w-200px text-center '>
                      <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item.mobileNumber}
                      </span>
                    </td>
                    <td className='min-w-200px text-center '>
                      <span className='text-dark fw-bold  d-block mb-1  fs-6 text-center'>
                        <span className='badge badge-light-success'>
                          {item?.status === 1 ? 'Active' : 'InActive'}
                        </span>
                      </span>
                    </td>
                    <td className='min-w-200px text-center '>
                      <span className='text-dark fw-bold  d-block mb-1 fs-6 text-cente'>
                        {item?.roles[0]?.name}
                      </span>
                    </td>
                    {permissions.includes(pagePermissions.EditCmsUser) && (
                      <td className='text-center'>
                        <Link
                          to={'/ChargePointEdit/' + item.id}
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </Link>
                        {/* <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                          onClick={() => EmailHandler(item.id)}
                        >
                          <KTIcon iconName='switch' className='fs-3' />
                        </button> */}
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header bg-success'>
                <h5 className='modal-title text-white'>Confirmation</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-success ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body'>
                <div className='text-start'>
                  <h3>Are you sure you want to deactivate {value?.email}</h3>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                  No
                </button>
                <button type='button' className='btn btn-light-success' onClick={() => {}}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget14}
