import * as Yup from 'yup'

export const ChargerValidation = Yup.object({
    name: Yup.string().required('Charger name is required'),
    stationId: Yup.string().required('Please Select State'),
    communication: Yup.string().required('Communication type is required'),
    charge_type: Yup.string().required('Charge type is required'),
    status: Yup.string().required('Status is required'),
    // state: Yup.string().required('State is required'),
    connectors: Yup.array().of(
        Yup.object({
            connector_ID: Yup.number().required('Connector ID is required'),
            port_type: Yup.string().required('Port type is required'),
            power_output: Yup.number().required('Power output is required').positive('Power output must be a positive number'),
            tariffId: Yup.string().required('Tariff ID is required'),
        })
    ).min(1, 'At least one connector is required').required('Connectors are required'),
})