import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { getRequest } from '../../../../pages/Helpers/Requests'
import { Link } from 'react-router-dom'

export default function FaultedConnectors() {
  const [faultedConnectors, setFaultedConnectors] = useState<any>([])
  const getFaultedConnectors = async () => {
    try {
      const res = await getRequest('/chargebox/faulted')
      console.log(res)
      if (res && res.data) {
        setFaultedConnectors(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getFaultedConnectors()
  }, [])
  return (
    <div>

      <div className={`card me-2 `}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>Faulted Connectors</h3>
          <div className='card-toolbar d-flex gap-5'>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-2'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Sl No.</th>
                  <th className='min-w-210px'>Station</th>
                  <th className='min-w-210px'>Charger</th>
                  <th className='min-w-210px'>Connector</th>
                  <th className='min-w-210px'>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {faultedConnectors?.map((item: any, index: number) => {
                  return (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>
                        <div className='text-dark fw-bold fs-6'>
                          {item?.station?.hub?.hubId}
                        </div>
                        <span>{item?.station?.hub?.name}</span>
                      </td>
                      <td>
                        <Link to={'/charger/' + item.station?.id}>
                          <span className='text-dark fw-bold text-hover-success fs-6'>
                            {item?.station?.stationId}
                          </span>

                          <div>
                            <span className='text-dark text-hover-success'>{item?.station?.name}</span>
                          </div>
                        </Link>
                      </td>
                      <td>{item?.connector_ID}</td>
                      <td>{item?.status} </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}

      </div>

    </div>
  )
}
