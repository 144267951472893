import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { getRequest, postRequest } from '../Helpers/Requests'
import { CmsUserinitialValues } from '../Helpers/AllInitialValues'
import { CmsUserValidtaion } from '../js/CmsUserValidtaion'

const AddCms = () => {
  const navigate = useNavigate()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    fetchAssignableRoles()
  }, [])

  const fetchAssignableRoles = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/roles`)
      if (res && res.data) {
        setRoles(res.data.data)
      }
    } catch (error) {
      console.error('Error fetching roles:', error)
    }
  }

  const handleSubmit = async (values: any) => {
    try {
      const res = await postRequest(`/cms/users/`, values)
      if (res && res.data) {
        toast.success('Created Successfully ✅')
        setTimeout(() => {
          navigate(-1)
        }, 1000)
      }
    } catch (error) {
      console.error('Error creating CMS user:', error)
    }
  }

  const state = [
    'Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ];



  const renderError = (errors: any, fieldName: string) => (
    <span className='text-danger fs-5'>{errors[fieldName] && errors[fieldName]}</span>
  )

  return (
    <div className='mt-n5'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>ADD NEW CMS USER</h3>
        </div>
        <div className='card-body'>
          <Formik initialValues={CmsUserinitialValues}
            onSubmit={handleSubmit}
            validationSchema={CmsUserValidtaion}
          >
            {({ errors }) => (
              <Form>
                <div className='row pt-5'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Operator Name'
                        name='OperatorName'
                      />
                      {renderError(errors, 'OperatorName')}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        name='email'
                      />
                      {renderError(errors, 'email')}
                    </div>
                  </div>
                </div>

                {/* Group, Country, State */}
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field as='select' className='form-select' name='group'>
                        <option value=''>Select Group</option>
                        {roles.map((role: any, index) => (
                          <option key={index} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </Field>
                      {renderError(errors, 'group')}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field as='select' className='form-select' name='country'>
                        <option value=''>Select Country</option>
                        <option value='India'>India</option>
                      </Field>
                      {renderError(errors, 'country')}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field as='select' className='form-select' name='state'>
                        <option value=''>Select State</option>
                        {/* List of States */}
                        {state?.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </Field>
                      {renderError(errors, 'state')}
                    </div>
                  </div>
                </div>

                {/* City, Pincode, Mobile */}
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field type='text' className='form-control' placeholder='City' name='city' />
                      {renderError(errors, 'city')}
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Pincode'
                        name='pincode'
                      />
                      {renderError(errors, 'pincode')}
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='mb-10'>
                      <Field as='select' className='form-select' name='countryCode'>
                        <option value='+91'>India</option>
                        <option value='+977'>Nepal</option>
                        <option value='+1'>United States</option>
                      </Field>
                      {renderError(errors, 'countryCode')}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-control'
                        placeholder='Mobile Number'
                        name='mobileNumber'
                      />
                      {renderError(errors, 'mobileNumber')}
                    </div>
                  </div>
                </div>

                {/* Address, GST Number */}
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address line1'
                        name='address1'
                      />
                      {renderError(errors, 'address1')}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Address line2'
                        name='address2'
                      />
                      {renderError(errors, 'address2')}
                    </div>
                  </div>
                </div>

                {/* AMC Dates */}
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <label htmlFor='startDate'>AMC Start Date</label>
                      <Field type='date' className='form-control' id='startDate' name='AMCstart' />
                      {renderError(errors, 'AMCstart')}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <label htmlFor='endDate'>AMC End Date</label>
                      <Field type='date' className='form-control' id='endDate' name='AMCend' />
                      {renderError(errors, 'AMCend')}
                    </div>
                  </div>
                </div>

                {/* Submit and Reset Buttons */}
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button type='reset' className='btn btn-light-primary'>
                      Reset
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button type='submit' className='btn btn-light-success'>
                      Submit
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {/* Confirmation Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header bg-success'>
              <h5 className='modal-title text-white'>Confirmation message</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                x
              </div>
            </div>
            <div className='modal-body'>
              <p>Your data is not saved, Are you sure you want to exit?</p>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                No
              </button>
              <button
                type='button'
                data-bs-dismiss='modal'
                onClick={() => navigate('/UserManagment/User/CmsUsers')}
                className='btn btn-light-success'
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCms
