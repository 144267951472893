import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getRequest} from '../Helpers/Requests'
import DeviceManagement from './DeviceManagement'
import DeviceSpecification from './DeviceSpecification'
import DeviceConfiguration from './DeviceConfiguration'
import DeviceTransaction from './DeviceTransaction'
import DeviceLogos from './DeviceLogos'
import StationQrCode from './StationQrCode'
import StationSettings from './StationSettings'
import StationChargeSessions from './StationChargeSessions'
import {useAuth} from '../../modules/auth'

const StationDetailsHeader = () => {
  const [stationId, setStationId] = useState('')
  const [connectors, setConnectors] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [stationName, setStationName] = useState('')
  const {pointId} = useParams()
  const {permissions} = useAuth()

  const headings = [
    {
      title: 'Device Specification',
      permission: 'ViewSpecification',
      component: <DeviceSpecification />,
    },
    {
      title: 'Device Management',
      permission: 'ViewDeviceManagement',
      component: <DeviceManagement stationId={stationId} id={pointId} connectors={connectors} />,
    },
    {
      title: 'Device Configuration',
      permission: 'ViewConfiguration',
      component: <DeviceConfiguration stationId={stationId} />,
    },
    {
      title: 'Device Transaction',
      permission: 'ViewDeviceTransactions',
      component: <DeviceTransaction stationId={stationId} />,
    },
    {
      title: 'Device Logs',
      permission: 'ViewLogs',
      component: <DeviceLogos stationId={stationId} />,
    },
    {
      title: 'QR Codes',
      permission: 'all',
      component: <StationQrCode stationId={stationId} connectors={connectors} />,
    },
    {
      title: 'Settings',
      permission: 'ViewLogConfiguration',
      component: <StationSettings stationId={stationId} />,
    },
    {
      title: 'Charging Sessions',
      permission: 'ViewChargingSession',
      component: <StationChargeSessions stationId={stationId} />,
    },
  ]

  useEffect(() => {
    const fetchStationDetails = async () => {
      try {
        const res = await getRequest(`/charger/${pointId}`)
        if (res?.data) {
          const data = res.data.data
          setStationName(data.name)
          setStationId(data.stationId)
          setConnectors(data.connectors)
        }
      } catch (error) {
        console.error('Error fetching station details:', error)
      }
    }

    fetchStationDetails()
  }, [pointId])

  useEffect(() => {
    const storedTab = localStorage.getItem('selectedTab')
    if (storedTab !== null) {
      setActiveTab(parseInt(storedTab))
    }
  }, [])

  const handleTabClick = (index) => {
    setActiveTab(index)
    localStorage.setItem('selectedTab', index.toString())
  }

  const filteredHeadings = headings.filter(
    (item) => item.permission === 'all' || permissions?.includes(item.permission)
  )

  return (
    <div>
      <div className='text-end mb-5'></div>
      <div className='card card-custom'>
        <div className='card-body mt-5'>
          <h1 className='mb-5'>{stationName}</h1>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5'>
            {filteredHeadings.map((heading, index) => (
              <li className='nav-item' key={index}>
                <a
                  className={`nav-link ${activeTab === index ? 'active' : ''}`}
                  data-bs-toggle='tab'
                  href={`#kt_tab_pane_${index}`}
                  onClick={() => handleTabClick(index)}
                >
                  {heading.title}
                </a>
              </li>
            ))}
          </ul>
          <div className='tab-content' id='myTabContent'>
            {filteredHeadings.map((heading, index) => (
              <div
                key={index}
                id={`kt_tab_pane_${index}`}
                className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
              >
                {activeTab === index && heading.component}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StationDetailsHeader
