import React from 'react'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import toast from 'react-hot-toast'
import {KTSVG} from '../../../_metronic/helpers'
import {postRequest} from '../Helpers/Requests'

export default function AddConnectorType({getAllConnectorType}) {
  const initialValues = {
    name: '',
    image: null,
    powerType: '',
  }

  const handleSubmit = async (values, {resetForm}) => {
    const formData = new FormData()
    if (values?.image) {
      formData.append('image', values.image)
    }
    formData.append('name', values.name)
    formData.append('powerType', values.powerType)
    formData.forEach((value, key) => {
      console.log(key, value)
    })
    try {
      const res = await postRequest('/connector-type', formData)
      if (res && res.data) {
        toast.success(res.data.message)
        getAllConnectorType()
        resetForm()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response?.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='add_connector'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Add Connector</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
              {({setFieldValue}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Connector Name</label>
                      <Field
                        placeholder='Connector Name'
                        name='name'
                        className='form-control'
                        type='text'
                      />
                      <ErrorMessage name='name' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Power Type</label>
                      <Field
                        as='select'
                        name='powerType'
                        className='form-control '
                        onChange={(e) => {
                          setFieldValue('powerType', e.target.value)
                        }}
                      >
                        <option value=''>Select Power Type</option>
                        {['AC', 'DC'].map((item: any) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          )
                        })}
                      </Field>
                      <ErrorMessage name='powerType' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Connector image</label>
                      <input
                        placeholder='Image'
                        type='file'
                        className='form-control'
                        onChange={(event: any) => {
                          setFieldValue('image', event.target.files[0])
                        }}
                      />
                      <ErrorMessage name='image' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
