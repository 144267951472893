/* eslint-disable */
import React from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../helpers'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'
import { getRequest, postRequest } from '../../../../app/pages/Helpers/Requests'

type Props = {
  className: string
}

interface Group {
  id: number
  group: String
  OperatorName: String
}

export const CmsTable = {
  username: 'UserName',
  email: 'Email Id',
  mobilenumber: 'Mobile Number',
  status: 'Status',
  group: 'Group Type',
}

const TableWidget100: React.FC<Props> = ({ className }) => {
  let { pointId } = useParams()
  const [CMSUsers, setCMSUsers] = useState<any>([])
  const [groupType, setGroupType] = useState('All')

  const userData =
    groupType == 'All' ? CMSUsers : CMSUsers.filter((item) => item.group == groupType)

  const getAllCmsUsers = async () => {
    try {
      const res = await getRequest(`/roles/${pointId}`)
      console.log(res?.data.data.role.CmsUsers);
      if (res && res.data) {
        setCMSUsers(res?.data.data.role.CmsUsers)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const handleOnChange = (e) => {
    e.preventDefault()
    setGroupType(e.target.value)
  }

  const handleAssignGroup = async (id: any) => {
    try {
      let data = { roleId: pointId, userId: id }
      const res = await postRequest('/roles/assign', data)
      if (res && res.data) {
        console.log(res.data)
        toast.success(`Role assigned to user successfully`)
      }
    } catch (error: any) {
      console.log(error.message)
      toast.error('User already has the specified role')
    }
  }

  useEffect(() => {
    getAllCmsUsers()
  }, [])
  return (
    <div className={`card ${className}`}>

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'></span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar d-flex gap-5'>
          {/* begin::Menu */}
          <div>
            <select className='form-select' onChange={handleOnChange}>
              <option value='All'>ALL</option>
              <option value='Admin'>ADMIN</option>
              <option value='CPO'>CPO</option>
              <option value='Operator'>Operator</option>
              <option value='SUBCPO'>SubCPO</option>
              <option value='SubOperator'>SubOperator</option>
              <option value='Testing'>Testing</option>
            </select>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-210px'>{CmsTable.username}</th>
                <th className='min-w-210px'>{CmsTable.email}</th>
                <th className='min-w-210px'>{CmsTable.mobilenumber}</th>
                <th className='min-w-210px'>{CmsTable.status}</th>
                {/* <th className='min-w-210px'>{CmsTable.group}</th> */}
                {/* <th className='min-w-210px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {userData?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={'/CMSsinglepage/' + item.id}
                            className='text-dark fw-bold text-hover-success fs-6'
                          >
                            {item.OperatorName}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.email}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.mobileNumber}
                      </a>
                    </td>
                    <td>
                      <a href='#' className=' fw-bold d-block fs-6 badge badge-light-success'>
                        Active
                      </a>
                    </td>
                    {/* <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.group}
                      </a>
                    </td> */}
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {/* <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                          // data-bs-toggle='modal'
                          // data-bs-target='#kt_modal_1'
                          onClick={() => {
                            handleAssignGroup(item.id)
                          }}
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </button> */}
                        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                          <div className='modal-dialog'>
                            <div className='modal-content'>
                              <div className='modal-header  bg-success'>
                                <h5 className='modal-title text-white'>Confirmation</h5>
                                {/* <div
                                  className='btn btn-icon btn-sm btn-active-light-success ms-2 text-white'
                                  data-bs-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                  />
                                </div> */}
                              </div>
                              {/* <div className='modal-body d-flex  align-items-center'>
                                <h5>
                                  Are You Sure To Assign This Group-{groupData?.group} To{' '}
                                  {item.OperatorName}
                                </h5>
                              </div> */}
                              {/* <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-light-danger'
                                  data-bs-dismiss='modal'
                                >
                                  No
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-light-success'
                                  data-bs-dismiss='modal'
                                >
                                  Yes
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TableWidget100 }
