/* eslint-disable */
import React, { useState } from 'react'
import PaginatedItems from '../../../../app/UI/PaginatedItems'

type Props = {
  className: string
  transactions: any[]
  setPage: any
  pages: any
  currentPage: any
}

export const DTable = {
  id: 'Transaction Id',
  connector: 'Connector Id',
  username: 'UserName',
  start: 'Start Date',
  stop: 'Stop Date',
  duration: 'Duration',
  meter: 'Total Meter value(Wh)',
  platform: 'Platform',
  status: 'Status',
}


const TablesWidget99: React.FC<Props> = ({ transactions, setPage, pages, currentPage, className }) => {
  const [selectedTransaction, setSelectedTransaction] = useState("");
  return (
    <div className={`card ${className}`}>

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px'>{DTable.id}</th>
                <th className='min-w-210px'>{DTable.connector}</th>
                <th className='min-w-210px'>{DTable.username}</th>
                <th className='min-w-210px'>{DTable.start}</th>
                <th className='min-w-210px'>{DTable.stop}</th>
                <th className='min-w-210px'>{DTable.duration + '(Mins)'}</th>
                <th className='min-w-210px'>{DTable.meter}</th>
                <th className='min-w-210px'>Amount(Rs)</th>
                <th className='min-w-110px'>Reason</th>
                <th className='min-w-210px'>{DTable.status}</th>
              </tr>
            </thead>
            {
              transactions.length === 0 && <h1>No Transactions haven't recorded till now</h1>
            }
            <tbody>
              {transactions && transactions.length > 0 &&
                transactions?.map((transaction: any, index: any) => {
                  const updatedAt = new Date(transaction.updatedAt)
                  const createdAt = new Date(transaction.createdAt)
                  const timeDifference = updatedAt.getTime() - createdAt.getTime()
                  const secondsDifference = timeDifference / 1000;
                  const localStartTime = new Date(transaction?.createdAt).toLocaleTimeString();
                  const localStopTime = new Date(transaction?.updatedAt).toLocaleTimeString();
                  return (
                    <tr key={index}>
                      <td>
                        <a
                          data-bs-toggle='modal'
                          data-bs-target='#transaction_details'
                          className='cursor-pointer text-black'
                          onClick={() => {
                            setSelectedTransaction(transaction.transactionId)
                          }}
                        >
                          {transaction?.transactionId}
                        </a>
                      </td>
                      <td>{transaction?.connectorId}</td>
                      <td>{transaction?.user?.firstName}</td>
                      <td>
                        {transaction?.startDate?.split('T')[0] +
                          ' ' +
                          localStartTime}
                      </td>
                      <td>
                        {transaction?.stopDate &&
                          transaction?.stopDate?.split('T')[0] +
                          ' ' +
                          localStopTime}
                      </td>
                      <td>
                        {(secondsDifference / 60).toFixed(1)}
                      </td>
                      <td>{transaction?.total_wh}</td>
                      <td>{transaction?.price}</td>
                      <td>{transaction?.reason}</td>
                      <td>
                        {transaction?.status == 0 ? (
                          <a href='#' className={`btn btn-sm btn-warning me-3`}>
                            Charging
                          </a>
                        ) : (
                          <a href='#' className={`btn btn-sm btn-success me-3`}>
                            Completed
                          </a>
                        )}
                      </td>
                    </tr>
                  )
                })}

            </tbody>

          </table>
          {/* end::Table */}
          <PaginatedItems setPage={setPage} pages={pages} currentPage={currentPage} />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget99 }
