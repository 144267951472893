/* eslint-disable */

import React, {useEffect, useState} from 'react'
import AvailableRoles from './AvailableRoles'
import AssignedRoles from './AssignedRoles'
import AlertButtons from './AlertButtons'

import {roles, assignedroles} from '../js/AvailableRoles'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {getRequest} from '../Helpers/Requests'
import {useAuth} from '../../modules/auth'
const pagePermissions = {
  assignPermission: 'AssignPermission',
  removePermission: 'RemovePermission',
}
const GroupRoleMapping = () => {
  const {pointId} = useParams()
  const [assignedRoles, setAssignedRoles] = useState([])
  const {permissions} = useAuth()
  // function to get role of user
  const getRole = async () => {
    try {
      const res = await getRequest(`/roles/${pointId}`)
      setAssignedRoles(res?.data.data.role.Permissions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getRole()
  }, [])
  return (
    <div className='row g-5'>
      <div className='col-lg-6 col-md-6 col-sm-12'>
        <div className='card card-custom'>
          <div className='card-header d-flex justify-content-center'>
            <h3 className='card-title text-black'>Available Permissions</h3>
          </div>
          <div className='card-body card-scroll h-400px m-0 p-0 w-Full'>
            <AvailableRoles assignedRoles={assignedRoles} getRole={getRole} />
          </div>
        </div>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12'>
        <div className='card card-custom'>
          <div className='card-header  d-flex justify-content-center'>
            <h3 className='card-title text-black'>Assigned Permissions</h3>
          </div>
          <div className='card-body card-scroll h-400px m-0 p-0 w-Full'>
            <AssignedRoles assignedroles={assignedRoles} getRole={getRole} />
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end mt-10'>{/* <AlertButtons /> */}</div>
    </div>
  )
}

export default GroupRoleMapping
