import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../helpers'
import AddCarBrand from '../../../../app/pages/Components/AddCarBrand'
import EditCarBrand from '../../../../app/pages/Components/EditCarBrand'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'
import AddCar from '../../../../app/pages/Components/AddCar'
import EditCar from '../../../../app/pages/Components/EditCar'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'

const brandPermissions = {
  CreateCarBrand: 'CreateCarBrand',
  EditCarBrand: 'EditCarBrand',
  DeleteCarBrand: 'DeleteCarBrand',
}

const carPermissions = {
  CreateCar: 'CreateCar',
  EditCar: 'EditCar',
  DeleteCar: 'DeleteCar',
}
export default function CarBrandTable() {
  const [carBrand, setCarBrand] = useState([])
  const [modalId, setModalId] = useState('')
  const [car, setCar] = useState([])
  const [carId, setCarId] = useState<any>('')
  const [brandId, setBrandId] = useState(null)
  const [type, setType] = useState<any>()
  const {permissions} = useAuth()

  const getCarBrands = async () => {
    try {
      const res = await getRequest(`/car-brand`)
      if (res && res.data) {
        console.log(res.data)
        setCarBrand(res?.data?.carBrands)
        setBrandId(res?.data?.carBrands[0]?.id || null)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getAllCar = async () => {
    try {
      const res = await getRequest(`/car-brand/brand/${brandId}`)
      if (res && res.data) {
        console.log(res.data?.carBrands?.cars)
        setCar(res.data?.carBrands?.cars)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const DeleteCar = async () => {
    try {
      const res = await deleteRequest(`/car/${carId}`)
      if (res) {
        toast.success('Deleted SuccessFully ✅')
        getAllCar()
      }
    } catch (error) {
      toast.error('Unable To Delete Data ❌')
    }
  }

  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/car-brand/${modalId}`)
      if (res) {
        toast.success('Deleted SuccessFully ✅')
        getCarBrands()
      }
    } catch (error) {
      toast.error('Unable To Delete Data ❌')
    }
  }
  useEffect(() => {
    getAllCar()
  }, [brandId])
  useEffect(() => {
    getCarBrands()
  }, [])

  return (
    // brand
    <div className=' '>
      <div className='row   '>
        <div className='col-lg-6 col-md-6 col-sm-12  card'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>Car Brands</h3>
            <div className='card-toolbar d-flex gap-5'>
              {/* brand */}
              {permissions.includes(brandPermissions.CreateCarBrand) && (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                  data-bs-toggle='modal'
                  data-bs-target='#add_carBrand'
                >
                  <a href='#' className='text-dark fw-bold d-block fs-6'>
                    <KTIcon iconName='plus' className='fs-3 btn btn-sm btn-success' />
                  </a>
                </button>
              )}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-210px'>Brand Name</th>
                    {(permissions.includes(brandPermissions.DeleteCarBrand) ||
                      permissions.includes(brandPermissions.EditCarBrand)) && (
                      <th className='min-w-210px d-flex justify-content-end '>Actions</th>
                    )}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {carBrand.map((item: any) => {
                    return (
                      <tr key={item?.id}>
                        <td>
                          <div
                            className='d-flex align-items-center '
                            onClick={() => setBrandId(item?.id)}
                          >
                            <div className='symbol symbol-45px me-5 '>
                              <img src={item?.image} alt='' className='' />
                            </div>

                            <Link to='#' className='link-dark text-hover-success'>
                              <div
                                className='d-flex justify-content-start fs-4 flex-column text-hover-success'
                                style={{color: item?.id === brandId ? 'green' : '#504E4E'}}
                              >
                                {item?.brandName}{' '}
                              </div>
                            </Link>
                          </div>
                        </td>
                        {(permissions.includes(brandPermissions.DeleteCarBrand) ||
                          permissions.includes(brandPermissions.EditCarBrand)) && (
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                              {permissions.includes(brandPermissions.EditCarBrand) && (
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                                  data-bs-toggle='modal'
                                  data-bs-target='#edit_carBrand'
                                  onClick={() => setModalId(item?.id)}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </button>
                              )}

                              {permissions.includes(brandPermissions.DeleteCarBrand) && (
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                                  data-bs-toggle='modal'
                                  data-bs-target='#confirm_modal'
                                  onClick={() => {
                                    setModalId(item?.id)
                                    setType(1)
                                  }}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </button>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}

          <AddCarBrand getAllBrands={getCarBrands} />
          <EditCarBrand id={modalId} getAllBrands={getCarBrands} />
          <ConfirmModal
            text={`Are you Sure. You want to delete the  ${type == 1 ? 'Car Brand' : 'Car'}? `}
            handleSubmit={type == 1 ? DeleteHandler : DeleteCar}
          />
        </div>

        {/* car */}
        <div className='col-lg-6 col-md-6 col-sm-12  card'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>Cars</h3>
            <div className='card-toolbar d-flex gap-5'>
              {permissions.includes(carPermissions.CreateCar) && (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                  data-bs-toggle='modal'
                  data-bs-target='#add_car'
                >
                  <a href='#' className='text-dark fw-bold d-block fs-6'>
                    <KTIcon iconName='plus' className='fs-3 btn btn-sm btn-success' />
                  </a>
                </button>
              )}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-210px'>Car Name</th>
                    <th className='min-w-210px'>Car Type</th>
                    <th className='min-w-100px'>Port Type</th>
                    {(permissions.includes(carPermissions.DeleteCar) ||
                      permissions.includes(carPermissions.EditCar)) && (
                      <th className='min-w-210px d-flex justify-content-end '>Actions</th>
                    )}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {car?.map((item: any) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <div className='d-flex align-items-center  '>
                            <div className='symbol symbol-45px me-5'>
                              <img src={item?.image} alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column hover'>
                              {' '}
                              {item?.name}{' '}
                            </div>
                          </div>
                        </td>
                        <td>{item?.carType} </td>
                        <td>{item?.connectorType?.map((item: any) => item.name).join(', ')} </td>
                        {(permissions.includes(carPermissions.DeleteCar) ||
                          permissions.includes(carPermissions.EditCar)) && (
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                              {permissions.includes(carPermissions.EditCar) && (
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                                  data-bs-toggle='modal'
                                  data-bs-target='#edit_car'
                                  onClick={() => setCarId(item?.id)}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </button>
                              )}
                              {permissions.includes(carPermissions.DeleteCar) && (
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                                  data-bs-toggle='modal'
                                  data-bs-target='#confirm_modal'
                                  onClick={() => {
                                    setCarId(item?.id)
                                  }}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </button>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
          <AddCar getAllCar={getAllCar} carbrands={carBrand} />
          <EditCar
            id={carId}
            getAllCar={() => {
              getAllCar()
              setCarId(null)
            }}
            carbrands={carBrand}
          />
          {/* <ConfirmModal
                    text='Are you Sure. You want to delete the Car Type? '
                    handleSubmit={DeleteCar}
                /> */}
        </div>
      </div>
    </div>
  )
}
