import React from 'react'
import {Formik, Form, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import { postRequest } from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast';



function UpdateFirmware({stationId}) {
  const initialValues= {
    location:'',
    retries:"",
    retry_interval:"",
    retrieve_date:""
  }
  const handleSubmit =async (values, {resetForm}) => {
    try {
      console.log(values)
        const res=await postRequest('/ocpp/remote/update-firmware/'+stationId,values);
        if(res && res.data){
            toast.success(res.data.message);
        }
    } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='update_firmware'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Update Firmware</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Location</label>
                        <Field placeholder="Location*" name='location' className='form-control' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Retries</label>
                        <Field  name='retries' className='form-control' placeholder="Retries" />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Retry Interval</label>
                        <Field  name='retry_interval' className='form-control' placeholder="Retry Interval" />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Retrieve Date/Time</label>
                        <input type="datetime-local" name='retrieve_date' className='form-control' placeholder="Retrieve Date/Time" onChange={(e)=>setFieldValue("retrieve_date",new Date(e.target.value))}/>
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateFirmware
