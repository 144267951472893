/* eslint-disable */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import toast from 'react-hot-toast'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'
import AddErrorCode from '../../modals/AddErrorCodeModal'
import UpdateErrorCode from '../../modals/UpdateErrorCode'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className?: string
}

// {
//     code: DataTypes.INTEGER,
//     info: DataTypes.STRING,
//     status: DataTypes.STRING,
//     errorCode: DataTypes.STRING,
//     responseText: DataTypes.TEXT,
//   },

interface Error {
  id: number
  code: number
  info: string
  status: string
  errorCode: string
  responseText: string
}

const pagePermissions = {
  CreateErrorCode: 'CreateErrorCode',
  DeleteErrorCode: 'DeleteErrorCode',
  EditErrorCode: 'EditErrorCode',
}
const VendorErrorCodes: React.FC<Props> = ({className}) => {
  const [modalId, setModalId] = useState<any>()
  const [errorCodes, setErrorCodes] = useState<Error[]>([])
  const {permissions} = useAuth()

  const getErrorCodes = async () => {
    try {
      const res = await getRequest('/vendor-error-code')
      if (res && res.data) {
        console.log(res.data)
        setErrorCodes(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/vendor-error-code/${modalId}`)
      if (res && res.status == 204) {
        toast.success('Error Code deleted successfully')
        getErrorCodes()
        setModalId(undefined)
      }
    } catch (error: any) {
      console.error(error)
      toast.error(error.response?.data?.message || 'An error occurred')
    }
  }

  useEffect(() => {
    getErrorCodes()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vendor Error Codes</span>
        </h3>
        <div className='card-toolbar d-flex gap-5'>
          {permissions.includes(pagePermissions.CreateErrorCode) && (
            <button
              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
              data-bs-toggle='modal'
              data-bs-target='#add_error_code'
            >
              <a href='#' className='text-dark fw-bold d-block fs-6'>
                <KTIcon iconName='plus' className='fs-3 btn btn-sm btn-success' />
              </a>
            </button>
          )}
        </div>
      </div>
      <div className='card-body py-2'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px'>Vendor Code</th>
                <th className='min-w-210px'>ErrorCode</th>
                <th className='min-w-210px'>Charger Status</th>
                <th className='min-w-210px'>Info</th>
                <th className='min-w-210px'>Response Message</th>
                {(permissions.includes(pagePermissions.DeleteErrorCode) ||
                  permissions.includes(pagePermissions.EditErrorCode)) && (
                  <th className='min-w-210px text-end'>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {errorCodes.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>{item.code}</div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block fs-6'>{item.errorCode}</span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block fs-6'>{item.status}</span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block fs-6'>{item.info}</span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block fs-6'>{item.responseText}</span>
                    </td>
                    {(permissions.includes(pagePermissions.DeleteErrorCode) ||
                      permissions.includes(pagePermissions.EditErrorCode)) && (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                          {permissions.includes(pagePermissions.EditErrorCode) && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#update_error_code'
                              onClick={() => {
                                setModalId(item.id)
                              }}
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                          )}

                          {permissions.includes(pagePermissions.DeleteErrorCode) && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#confirm_modal'
                              onClick={() => {
                                setModalId(item.id)
                              }}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <AddErrorCode fetchData={getErrorCodes} />

      <UpdateErrorCode modalId={modalId} fetchData={getErrorCodes} setModalId={setModalId} />
      <ConfirmModal
        text='Are you Sure. You want to delete the Error Code ? '
        handleSubmit={DeleteHandler}
      />
    </div>
  )
}

export default VendorErrorCodes
