/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {TableOnes} from '../../../../app/pages/js/TableOne'
import {useParams} from 'react-router-dom'
import {DeviceTransactions} from '../../../../app/pages/js/DeviceTransaction'
import {getRequest} from './../../../../app/pages/Helpers/Requests'
import TransactionDetailsModal from '../../../../app/pages/Components/TransactionDetailsModal'
import {error} from 'console'
import {getDateFormat} from '../../../../functions'
import PaginatedItems from '../../../../app/UI/PaginatedItems'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import {DatePicker} from 'antd'
import TransactionModal from '../../modals/TransactionModal'
const {RangePicker} = DatePicker

type Props = {
  className?: String
}
export interface dtUpdates {
  config: String
  value: String
  description: String
  access: String
}
export const DTable = {
  id: 'Transaction Id',
  connector: 'Connector Id',
  username: 'UserName',
  start: 'Start Date',
  stop: 'Stop Date',
  duration: 'Duration',
  meter: 'Total Meter value(Wh)',
  platform: 'Platform',
  stopFrom: 'Stop From',
  status: 'Status',
}

const TotalDeviceTransaction: React.FC<Props> = ({className}) => {
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [transactions, setTransactions] = useState<any>([])
  const [gstTransactions, setGstTransactions] = useState([])
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [filter, setFilter] = useState('ALL')
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<any>(8)
  const [pages, setPages] = useState<any>(0)
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [downloading, setDownloading] = useState(false)
  const [stations, setStations] = useState<any>([])
  const [selectedStation, setSelectedStation] = useState('')

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    console.log(dates.map((item) => new Date(item)))
    setStartDate(new Date(dateStrings[0]))
    setEndDate(new Date(dateStrings[1]))
  }

  const getDateViceTransactions = async () => {
    try {
      const res = await getRequest(
        `/device-transaction/all/datewise?startDate=${startDate}&endDate=${endDate}&type=${filter.toLowerCase()}`
      )
      if (res?.status === 200) {
        return res.data.data
      }
    } catch (error: any) {
      console.error(error)
    }
  }
  const convertJsonToExcel = async () => {
    const dateWiseLogs = await getDateViceTransactions()
    let json = dateWiseLogs.map((item) => {
      const timeDifference =
        new Date(item?.updatedAt).getTime() - new Date(item?.createdAt)?.getTime()
      const secondsDifference = timeDifference / 1000
      return {
        TRANSACTIONID: item.transactionId,
        STATIONID: item.stationId,
        CONNECTORID: item.connectorId,
        NAME: `${item.user?.firstName} ${item.user?.lastName}`, // Concatenate first and last name
        MOBILENUMBER: item.user?.mobileNumber, // Use mobileNumber from user
        EMAIL: item.user?.email,
        GSTNUM: item.user?.gstNum || '', // Use gstNum from user (if present)
        STARTFROM: item.platform,
        STOPFROM: item.stopFrom,
        STARTDATE: item.startDate,
        STOPDATE: item.stopDate,
        'STARTSOC(%)': item.startSoc,
        'STOPSOC(%)': item.stopSoc,
        'CHARGINGDURATION(min)': (secondsDifference / 60).toFixed(2),
        STARTMETERVALUE: item.startMeterValue,
        STOPMETERVALUE: item.stopMeterValue, // Directly using stopMeterValue from item
        'ENERGY_CONSUMED(Kw)': (item.total_wh / 1000).toFixed(2),
        AMOUNT: item?.amount.toFixed(2),
        GST: item.gst?.toFixed(2),
        TOTAL: item.price?.toFixed(3),
        REASON: item.reason,
      }
    })
    const ws = XLSX.utils.json_to_sheet(json)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })
    let filename = `${new Date(startDate).toDateString()}-to-${new Date(endDate).toDateString()}`
    console.log(filename)
    saveAs(data, `${filename}.xlsx`)
  }
  const getPlatformStatus = (platform: string) => {
    if (platform === 'IOS') {
      return 'badge fs-7 fw-semibold badge-light-primary'
    } else if (platform === 'ANDROID') {
      return 'badge fs-7 fw-semibold badge-light-info'
    } else if (platform === 'WEB') {
      return 'badge fs-7 fw-semibold badge-light-success'
    } else {
      return 'badge fs-7 fw-semibold badge-light-danger'
    }
  }

  const getDeviceTransactions = async () => {
    try {
      const res = await getRequest(
        `/device-transaction?page=${page}&limit=${limit}&type=${filter?.toLowerCase()}&stationId=${selectedStation}`
      )
      if (res?.status === 200) {
        setFilteredTransactions(res.data.data)
        setPages(res?.data?.pagination?.totalPages)
        console.log('res', res)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getAllStation = async () => {
    try {
      const res = await getRequest(`/hub`)
      console.log(res)
      if (res) {
        setStations(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllStation()
  }, [])

  useEffect(() => {
    getDeviceTransactions()
  }, [filter, page, selectedStation])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Device Transactions</span>
        </h3>
        <div className='card-toolbar gap-3'>
          <div>
            <select
              className='form-select'
              onChange={(e) => {
                setSelectedStation(e.target.value)
              }}
            >
              <option value=''>Select Station</option>
              {stations?.map((station: any, index: number) => (
                <option key={index} value={station?.id}>
                  {station?.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              className='form-select'
              onChange={(e) => {
                setFilter(e.target.value)
              }}
            >
              <option value='All'>ALL</option>
              <option value='GST'>GST User Transactions</option>
            </select>
          </div>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-end py-2 px-8'>
        {/*  <div>
          <RangePicker
            showTime={true}
            onChange={handleDateChange}
            format='YYYY-MM-DD HH:mm:ss'
            style={{marginRight: '10px', flex: '1'}}
          />
          <button
            className='btn btn-sm btn-light-success'
            style={{flex: '0 0 auto'}}
            onClick={convertJsonToExcel}
            disabled={!startDate || !endDate || downloading}
          >
            {downloading ? 'Preparing File logs.xlsx' : 'Download'}
          </button>
        </div> */}
        <div>
          <button
            className='btn btn-sm btn-light-success  '
            data-bs-toggle='modal'
            data-bs-target='#transactionmodal'
          >
            <a href='#' className='text-dark fw-bold d-block fs-6'>
              download
            </a>
          </button>
        </div>
      </div>
      <TransactionModal />

      <div className='table-responsive '>
        <table className='table table-row-dashed table-striped gy-7 gs-7 '>
          <thead>
            <tr className='fw-bold fs-6 text-black border-bottom-2 border-gray-200'>
              <th className='min-w-210px'>{DTable.id}</th>
              <th className='min-w-210px'>ChargeBox Id</th>
              <th className='min-w-210px'>{DTable.connector}</th>
              <th className='min-w-210px'>{DTable.username}</th>
              <th className='min-w-210px'>{DTable.start}</th>
              <th className='min-w-210px'>{DTable.stop}</th>
              <th className='min-w-210px'>{DTable.duration + '(Mins)'}</th>
              <th className='min-w-210px'>{DTable.meter}</th>
              <th className='min-w-210px'>Mac Id</th>
              <th className='min-w-210px'>Amount(Rs)</th>
              <th className='min-w-210px'>GST(18%)</th>
              <th className='min-w-210px'>Total(Rs)</th>
              <th className='min-w-110px'>Reason</th>
              <th className='min-w-210px'>{DTable.platform}</th>
              <th className='min-w-210px'>{DTable.stopFrom}</th>
              <th className='min-w-210px'>{DTable.status}</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions &&
              filteredTransactions?.map((transaction: any, index: any) => {
                const updatedAt = new Date(transaction.updatedAt)
                const createdAt = new Date(transaction.createdAt)
                const timeDifference = updatedAt.getTime() - createdAt.getTime()
                const secondsDifference = timeDifference / 1000
                const localStartTime = new Date(transaction?.createdAt).toLocaleTimeString()
                const localStopTime = new Date(transaction?.updatedAt).toLocaleTimeString()
                return (
                  <tr key={index}>
                    <td>
                      <a
                        data-bs-toggle='modal'
                        data-bs-target='#transaction_details'
                        className='cursor-pointer text-black'
                        onClick={() => {
                          setSelectedTransaction(transaction.transactionId)
                        }}
                      >
                        {transaction?.transactionId}
                      </a>
                    </td>
                    <td>
                      <span className='fw-bold'>{transaction?.stationId}</span>
                      {/* {" "}({transaction?.connectorId}) */}
                    </td>
                    <td>{transaction?.connectorId}</td>
                    <td>{transaction?.user?.firstName}</td>
                    <td>{transaction?.startDate}</td>
                    <td>{transaction?.stopDate}</td>
                    <td>{(secondsDifference / 60).toFixed(1)}</td>
                    <td>{(transaction?.total_wh / 1000).toFixed(3)}</td>
                    <th>{transaction?.macId}</th>
                    <td>{transaction?.amount}</td>
                    <td>{transaction?.gst}</td>
                    <td>{transaction?.price}</td>
                    <td>{transaction?.reason}</td>
                    <td>
                      <span className={getPlatformStatus(transaction?.platform)}>
                        {transaction?.platform}
                      </span>
                    </td>
                    <td>
                      <span className={getPlatformStatus(transaction?.stopFrom)}>
                        {transaction?.stopFrom}
                      </span>
                    </td>
                    <td>
                      {transaction?.status == 0 ? (
                        <a href='#' className={`badge fs-7 fw-semibold badge-light-success me-3`}>
                          Charging
                        </a>
                      ) : (
                        <a href='#' className={`badge fs-7 fw-semibold badge-light-success me-3`}>
                          Completed
                        </a>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
          <TransactionDetailsModal transactionId={selectedTransaction} />
        </table>
        <PaginatedItems setPage={setPage} pages={pages} currentPage={page} />
      </div>
    </div>
  )
}

export {TotalDeviceTransaction}
