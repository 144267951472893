/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Formik, Form, Field} from 'formik'
import {AccountDetailsValidation} from '../js/AccountDetatilsValidation'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {getRequest, postRequest} from '../Helpers/Requests'

const EditCMSAC = () => {
  const {pointId} = useParams()
  const [accountDetails,setAccountDetails]=useState<any>()
  const [initialValues, setInitialValues] = useState({
    userId: pointId,
    accountHolderName: '',
    accountNumber: '',
    confirm_ac: '',
    accountType: '',
    bankName:"",
    ifscCode:""
  })

  const getAcDetails = async () => {
    try {
      const res = await getRequest('/account/user/' + pointId)
      if (res?.status===200) {
        setAccountDetails({...res.data.account,confirm_ac:res.data.account.accountNumber})
        setInitialValues({...res.data.account, confirm_ac: res.data.account.accountNumber})
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const submitHandler = async (values) => {
    try {
      const res = await postRequest('/account', values)
      if (res?.status===200) {
        toast.success('Account Updated successfully')
      }
    } catch (error: any) {
      console.log(error.message)
      toast.error('unable add or update account details')
    }
  }

  useEffect(() => {
    getAcDetails()
  }, [])
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-60px w-60px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/account.PNG')}
                alt='Metronic'
                className='p-3'
              />
            </div>
            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Account Details
            </a>
          </div>
          <div className='card-toolbar m-0'></div>
        </div>
        <div className='card-body'>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={AccountDetailsValidation}
            onSubmit={submitHandler}
          >
            {({errors}) => (
              <Form>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder='Account Holders Name'
                            name='accountHolderName'
                          />
                          <span className='text-danger fs-5'>
                            {errors.accountHolderName && errors.accountHolderName}
                          </span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder='Account Number'
                            name='accountNumber'
                          />
                          <span className='text-danger fs-5'>
                            {errors.accountNumber && errors.accountNumber}
                          </span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mb-10'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder='Confirm Account Number'
                            name='confirm_ac'
                          />
                          <span className='text-danger fs-5'>
                            {errors.confirm_ac && errors.confirm_ac}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='accountType'
                      >
                        <option>Account Type</option>
                        <option value='SAVINGS'>SAVINGS</option>
                        <option value='CURRENT'>CURRENT</option>
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.accountType && errors.accountType}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='IFSC Code'
                        name='ifscCode'
                      />
                      <span className='text-danger fs-5'>{errors.ifscCode && errors.ifscCode}</span>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Bank Name'
                        name='bankName'
                      />
                      <span className='text-danger fs-5'>{errors.bankName && errors.bankName}</span>
                    </div>
                  </div>
                </div>
                <div className='row'></div>
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button
                      type='button'
                      className='btn btn-light-primary me-5'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Reset
                    </button>
                    <button type='submit' className='btn btn-light-success'>
                      Update
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditCMSAC
