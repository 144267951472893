/* eslint-disable */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useState, useEffect} from 'react'
import toast from 'react-hot-toast'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'
import EditAmenity from '../../modals/EditAmenity'
import AddAmenity from '../../modals/AddAmenity'
import {useAuth} from '../../../../app/modules/auth'
type Props = {
  className?: string
}

const pagePermissions = {
  CreateAmenity: 'CreateAmenity',
  EditAmenity: 'EditAmenity',
  DeleteAmenity: 'DeleteAmenity',
}
const TablesWidget43: React.FC<Props> = ({className}) => {
  const [amenities, setAmenities] = useState<any>([])
  const [modalId, setModalId] = useState('')
  const {permissions} = useAuth()

  // function to get all cms users
  const getAmenities = async () => {
    try {
      const res = await getRequest(`/amenities`)
      if (res && res.data) {
        console.log(res.data.data)
        setAmenities(res.data.data)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  // function for handling delete requests
  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`${process.env.REACT_APP_API_URL}/amenities/${modalId}`)
      if (res) {
        toast.success('Deleted SuccessFully ✅')
        getAmenities()
      }
    } catch (error) {
      toast.error('Unable To Delete Data ❌')
    }
  }

  useEffect(() => {
    getAmenities()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Amenities</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>{cmsTableHeadings.subheading}</span> */}
        </h3>
        <div className='card-toolbar d-flex gap-5'>
          {permissions.includes(pagePermissions.CreateAmenity) && (
            <button
              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
              data-bs-toggle='modal'
              data-bs-target='#add_amenity'
            >
              <a href='#' className='text-dark fw-bold d-block fs-6'>
                <KTIcon iconName='plus' className='fs-3 btn btn-sm btn-success' />
              </a>
            </button>
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px'>Amenity</th>
                <th className='min-w-210px'>Type</th>
                {(permissions.includes(pagePermissions.DeleteAmenity) ||
                  permissions.includes(pagePermissions.EditAmenity)) && (
                  <th className='min-w-210px text-end'>Actions</th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {amenities.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img
                            src={
                              item.image ? item.image : toAbsoluteUrl('/media/avatars/blank.png')
                            }
                            alt=''
                          />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>{item.name}</div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.type}
                      </a>
                    </td>
                    {(permissions.includes(pagePermissions.DeleteAmenity) ||
                      permissions.includes(pagePermissions.EditAmenity)) && (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                          {permissions.includes(pagePermissions.EditAmenity) && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#edit_amenity'
                              onClick={() => {
                                setModalId((prev) => {
                                  return item.id
                                })
                              }}
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                          )}
                          {permissions.includes(pagePermissions.DeleteAmenity) && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#confirm_modal'
                              onClick={() => {
                                setModalId((prev) => {
                                  return item.id
                                })
                              }}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <AddAmenity getAmenities={getAmenities} />
      <EditAmenity id={modalId} getAmenities={getAmenities} />
      <ConfirmModal
        text='Are you Sure. You want to delete the CMS User ? '
        handleSubmit={DeleteHandler}
      />
    </div>
  )
}

export default TablesWidget43
