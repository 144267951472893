/* eslint-disable */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const {permissions, currentUser} = useAuth()

  const renderMenuItem = (item: any) => {
    if (item.subItems) {
      return (
        <SidebarMenuItemWithSub
          to={item.to}
          title={item.title}
          fontIcon={item.fontIcon}
          icon={item.icon}
        >
          {item.subItems.map((subItem: any, index: number) => (
            <SidebarMenuItem
              key={index}
              to={subItem.to}
              title={subItem.title}
              icon={subItem.icon}
            />
          ))}
        </SidebarMenuItemWithSub>
      )
    }

    return (
      <SidebarMenuItem
        key={item.to}
        to={item.to}
        icon={item.icon}
        title={item.title}
        fontIcon={item.fontIcon}
      />
    )
  }

  const renderSection = (section: any) => {
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
              {section.section}
            </span>
          </div>
        </div>
        {section.items.map((item: any, index: number) => renderMenuItem(item))}
      </>
    )
  }

  const sidebarMenuItems = [
    {
      to: '/dashboard',
      icon: 'chart-simple-2',
      title: 'Dashboard',
      fontIcon: 'bi-layers',
      permission: 'ViewDashboard',
    },
    {
      to: '/apps/analytics/menu',
      title: 'Analytics',
      fontIcon: 'bi-chat-left',
      icon: 'abstract-8',
      permission: 'ViewRevenue',
      globalPermission: 'ViewGlobalRevenue',
      subItems: [
        {to: '/apps/menu/Revenue', title: 'Revenue', icon: 'dollar', permission: 'ViewRevenue'},
        {
          to: '/apps/menu/ChargeBox',
          title: 'ChargeBox',
          icon: 'technology-4',
          permission: 'ViewRevenue',
        },
      ],
    },
    {
      section: 'Station Management',
      items: [
        {
          to: '/Chargestations/Charge/Stations',
          title: 'Station Management',
          icon: 'electricity',
          permission: 'ViewStations',
          globalPermission: 'GlobalChargingStation',
        },
      ],
    },
    {
      section: 'Tariff',
      items: [
        {
          to: '/Chargestations/Charge/Tariff',
          title: 'Tariff Management',
          fontIcon: 'bi-chat-left',
          icon: 'two-credit-cart',
          permission: 'ViewTariff',
          globalPermission: 'GlobalTariff',
        },
      ],
    },
    {
      section: 'CPO Management',
      items: [
        {
          to: '/Chargepoint',
          title: 'CPO Management',
          icon: 'gear',
          permission: 'ViewOperators',
        },
      ],
    },
    {
      section: 'Ev User',
      items: [
        {
          to: 'Evusers/EV',
          title: 'EV USERS',
          fontIcon: 'bi-chat-left',
          icon: 'car-2',
          permission: 'ViewEvUsers',
          subItems: [
            {
              to: '/Evusers/EV/UserList',
              title: 'User List',
              icon: 'people',
              permission: 'ViewEvUsers',
            },
            {
              to: '/Evusers/EV/Transactions',
              title: 'Transactions',
              icon: 'chart-simple-3',
              permission: 'ViewTransactions',
            },
            {
              to: '/Evusers/EV/walletTransactions',
              title: 'Wallet Transactions',
              icon: 'cheque',
              permission: 'ViewWallet',
            },
            {
              to: '/Evusers/EV/RfidTags',
              title: 'Rfid Tags',
              icon: 'tag',
              permission: 'ViewRFIDtags',
            },
          ],
        },
      ],
    },
    {
      section: 'Employee Management',
      items: [
        {
          to: 'Employees/data',
          title: 'Employee Management',
          fontIcon: 'bi-chat-left',
          icon: 'profile-user',
          permission: 'ViewPermission',
          subItems: [
            {
              to: '/UserManagment/User/Roles',
              icon: 'abstract-14',
              title: 'Permissions',
              fontIcon: 'bi-layers',
              permission: 'ViewPermissions',
            },
            {
              to: '/UserManagment/User/Groups',
              icon: 'focus',
              title: 'Roles',
              fontIcon: 'bi-layers',
              permission: 'ViewRoles',
            },
          ],
        },
        {
          to: '/UserManagment/User/CmsUsers',
          title: 'User Management',
          icon: 'people',
          permission: 'ViewCmsUser',
        },
      ],
    },
    {
      to: '/transactions',
      title: "Transaction's",
      icon: 'abstract-45',
      permission: 'ViewDeviceTransactions',
    },
    {
      to: '/total-connectors',
      title: "Connector's",
      icon: 'abstract-45',
      permission: 'ViewChargers',
    },
    {
      section: 'Bug Reports',
      items: [
        {
          to: '/all-reports',
          title: 'Bug Reports',
          icon: 'information-2',
          permission: 'ViewReports',
        },
      ],
    },
    {
      section: 'Administration',
      items: [
        {to: '/error-codes', title: 'Error Codes', icon: 'category', permission: 'ViewErrorCode'},
        {
          to: '/state-wise-gst',
          title: 'State GST',
          icon: 'educare',
          permission: 'ViewErrorCode',
        },
        {
          to: '/admin-controll',
          title: 'Admin Controll',
          icon: 'setting-4',
          permission: 'EditAdminOption',
        },
        {
          to: '/car/brand',
          title: 'Car Brand',
          fontIcon: 'bi-chat-left',
          icon: 'car',
          permission: 'ViewCarBrand',
        },
        {
          to: '/connector/type',
          title: 'Connector Types',
          icon: 'bi bi-diagram-3',
          permission: 'ViewConnectorType',
        },
        {
          to: '/amenity/amenities',
          title: 'Amenity Management',
          icon: 'category',
          permission: 'ViewAmenities',
        },
      ],
    },
  ]

  console.log('permissions at', permissions)

  const filteredSidebarMenuItems = sidebarMenuItems
    .map((item: any) => {
      if (item.items) {
        const filteredItems = item.items.filter((subItem) => {
          const hasPermission =
            permissions.includes(subItem.permission) ||
            permissions.includes(subItem.globalPermission)

          if (subItem.subItems) {
            subItem.subItems = subItem.subItems.filter(
              (subSubItem: any) =>
                permissions.includes(subSubItem.permission) ||
                permissions.includes(subSubItem.globalPermission)
            )
            return subItem.subItems.length > 0 || hasPermission
          }
          return hasPermission
        })

        return filteredItems.length > 0 ? {...item, items: filteredItems} : null
      }

      return permissions.includes(item.permission) || permissions.includes(item.globalPermission)
        ? item
        : null
    })
    .filter(Boolean)
  return (
    <>
      {filteredSidebarMenuItems.map((item: any, index: number) => {
        return item.section ? renderSection(item) : renderMenuItem(item)
      })}
    </>
  )
}

export {SidebarMenuMain}
