/* eslint-disable */

import React, { useEffect, useState } from 'react'
import PriceTypeFixed from './PriceTypeFixed'
import PriceTypeVariable from './PriceTypeVariable'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import toast, { Toaster } from 'react-hot-toast'
import { getRequest, postRequest } from '../Helpers/Requests'
import { generateNewVariablePrices } from '../Helpers/HelperFUnctions'
import { TariffEditInitialValues } from '../Helpers/AllInitialValues'
import * as Yup from 'yup'
import { TarrifValidation } from '../js/AddNewTariffValidation'

const AddTraiff = () => {
  const [operators, setOperators] = useState<any>([])
  const [operatorId, setOperatorId] = useState<any>(1);
  const [stations, setStations] = useState<any>([]);

  const getAllOperators = async () => {
    try {
      const res = await getRequest(`/cms/users/?field=OperatorName,id`)
      if (res && res.data) {
        setOperators(res.data.data.CmsUsers)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getAllStations = async () => {
    try {
      const response = await getRequest('/stations/operator/' + operatorId);
      if (response?.status === 200) {
        console.log(response.data.data)
        setStations((prev) => {
          return response.data.data
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  // function to submitting the form
  const handleSubmit = async (values: any, { setSubmitting }) => {
    try {
      const requestData = { ...values }
      if (values.priceType === 'Fixed') {
        delete requestData.variablePrices
        const res = await postRequest(`/tariff/addFixedTariff`, requestData)
        if (res && res.data) {
          toast.success('Data Created Successfully ✅')
          setTimeout(() => {
            navigate('/Chargestations/Charge/Tariff')
          }, 1000)
        }
      } else {
        delete requestData.fixedPrice
        let updatedVp = generateNewVariablePrices(values.variablePrices)
        requestData.variablePrices = updatedVp
        const res = await postRequest(`/tariff/addVariableTariff`, requestData)
        if (res && res.data) {
          toast.success('Data Created Successfully ✅')
          setTimeout(() => {
            navigate('/Chargestations/Charge/Tariff')
          }, 1000)
        }
      }
    } catch (err) {
      toast.error('Invalid Data. Please check and try again ❌')
      console.error(err)
    } finally {
      setSubmitting(false)
    }
  }

  const navigate = useNavigate()

  useEffect(() => {
    getAllOperators()
  }, [])

  useEffect(() => {
    if (operatorId !== null && operatorId !== undefined && operatorId !== " ") {
      getAllStations();
    }
  }, [operatorId])
  return (
    <div className='mt-n5'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title'>Add New Tariff</h3>
        </div>
        <div className='card-body'>
          <Formik initialValues={TariffEditInitialValues}
            onSubmit={handleSubmit}
            validationSchema={TarrifValidation}
          >
            {({ errors, values, setFieldValue, isSubmitting }) => (
              <Form>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        placeholder='Name'
                        name='name'
                        autoComplete='off'
                      />
                      <span className='text-danger fs-5'>{errors?.name}</span>
                    </div>
                  </div>
                  <div className='col-md-4 '>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='operatorId'
                        onClick={(e) => { setOperatorId((prev) => { return e.target.value }) }}
                      >
                        <option value={0}>Select Operator</option>
                        {operators.map((e: any, i: any) => {
                          return (
                            <option key={i} value={e.id}>
                              {e.OperatorName}
                            </option>
                          )
                        })}
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.operatorId && errors.operatorId}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-select'
                        aria-label='Select example'
                        placeholder='Price (excluding tax)'
                        name='fixedPrice.price'
                      />
                      <span className='text-danger fs-5'>
                        {errors.fixedPrice?.price && errors.fixedPrice?.price}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='number'
                        className='form-select'
                        aria-label='Select example'
                        placeholder='GST PERCENTAGE'
                        name='fixedPrice.gst'
                      />
                      <span className='text-danger fs-5'>
                        {errors.fixedPrice?.gst && errors.fixedPrice?.gst}
                      </span>
                    </div>
                  </div>
                  {/* <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select Station'
                        name='stationId'
                      >
                        <option>Select Station</option>
                        {stations.map((e: any, i: any) => {
                          return (
                            <option key={i} value={e.id}>
                              {e.stationName}
                            </option>
                          )
                        })}
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.operatorId && errors.operatorId}
                      </span>
                    </div>
                  </div> */}

                  {/* <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        disabled
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='country'
                      >
                        <option>Select Country</option>
                        <option value='United States'>United States</option>
                        <option value='India'>India</option>
                        <option value='Nepal'>Nepal</option>
                      </Field>
                      <span className='text-danger fs-5'>{errors.country && errors.country}</span>
                    </div>
                  </div> */}

                  {/* <div className='col-md-3'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        disabled
                        className='form-control'
                        placeholder='Currency'
                        name='currency'
                      />
                      <span className='text-danger fs-5'>{errors.currency && errors.currency}</span>
                    </div>
                  </div> */}
                </div>

                {/* <div className='row'>
                  <div className='col-md-12'>
                    <h3 className='mb-10'>Price Type</h3>
                    <div className='d-flex'>
                      <div className='mb-10'>
                        <div className='form-check form-check-custom h3 form-check-solid form-check-sm'>
                          <Field
                            className='form-check-input-sm'
                            name='priceType'
                            type='radio'
                            id='fixed'
                            value='Fixed'
                          />
                          <label className='form-check-label fs-3' htmlFor='fixed'>
                            Fixed
                          </label>
                        </div>
                      </div>
                      <div className='mb-10 ms-5'>
                        <div className='form-check h3 form-check-custom form-check-solid form-check-sm'>
                          <Field
                            className='form-check-input-sm'
                            name='priceType'
                            type='radio'
                            id='variable'
                            value='variable'
                          />
                          <label className='form-check-label fs-3' htmlFor='variable'>
                            Variable
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className='row'>
                  <div className='col-md-12'>
                    {values.priceType === 'variable' ? (
                      <PriceTypeVariable
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    ) : (
                      <PriceTypeFixed errors={errors} values={values} />
                    )}
                  </div>
                </div> */}
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                      <div className='modal-dialog'>
                        <div className='modal-content'>
                          <div className='modal-header bg-success'>
                            <h5 className='modal-title text-white'>Confirmation message</h5>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-success ms-2'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              x
                            </div>
                          </div>
                          <div className='modal-body'>
                            <h5>Your data is not saved, Are you sure to exit ?</h5>
                          </div>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-light-danger'
                              data-bs-dismiss='modal'
                            >
                              No
                            </button>
                            <button
                              type='button'
                              data-bs-dismiss='modal'
                              onClick={() => navigate('/Chargestations/Charge/Tariff')}
                              className='btn btn-light-success'
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-10 ms-2'>
                    {/* <PopUpSumbit text={'Submit'} /> */}
                    <button
                      type='submit'
                      className='btn btn-light-success ms-3'
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AddTraiff
