/* eslint-disable */

import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {FaCheck} from 'react-icons/fa'
import {KTSVG} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {getRequest, postRequest} from '../Helpers/Requests'
import {useAuth} from '../../modules/auth'

export interface RoleUpdate {
  id: any
  role: string
}

const pagePermissions = {
  assignPermission: 'AssignPermission',
  removePermission: 'RemovePermission',
}
const AvailableRoles = ({assignedRoles, getRole}) => {
  let {pointId} = useParams()
  const [freeRoles, setFreeRoles] = useState<any>([])
  const {permissions} = useAuth()
  const getAllRoles = async () => {
    try {
      const res = await getRequest('/permissions')
      if (res && res.data) {
        let filt = res?.data.data.filter((e: any) => {
          let finded = assignedRoles.find((item: any) => item.name === e.name)
          if (!finded) return e
        })
        console.log(filt)
        setFreeRoles(filt)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const assignRoleToGroup = async (id: any) => {
    const data = {permissionId: id, roleId: pointId}
    try {
      const confirm = window.confirm('Do you Want to assign this Role to this Operators')
      if (confirm) {
        const res = await postRequest('/permissions/assign', data)
        if (res && res.data) {
          getRole()
        }
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  useEffect(() => {
    getAllRoles()
  }, [assignedRoles])
  return (
    <div>
      <ul className='list-group rounded-0'>
        {freeRoles?.map((item: any, index: number) => (
          <li
            key={index}
            className='list-group-item text-dark fw-bold py-7 d-flex fs-6 align-items-center gap-5'
          >
            {permissions.includes(pagePermissions.assignPermission) && (
              <div className='d-flex justify-content-end flex-shrink-0'>
                <FaCheck
                  size={20}
                  className='text-success hoverable'
                  style={{cursor: 'pointer'}}
                  onClick={() => assignRoleToGroup(+item.id)}
                />
              </div>
            )}
            <label>{item.name}</label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AvailableRoles
