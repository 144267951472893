import * as Yup from 'yup'

export const CmsUserValidtaion = Yup.object({
    OperatorName: Yup.string().required('Operator Name is required'),
    email: Yup.string().required('Please enter email'),
    group: Yup.number().required('Please choose your expiry date'),
    country: Yup.string().required('Please leave your country'),
    state: Yup.string().required('Please enter your state'),
    city: Yup.string().required('Please enter your city'),
    pincode: Yup.string().required('Please enter your pincode'),
    countryCode: Yup.string().required('Please enter your country code'),
    mobileNumber: Yup.string().required('Please enter your mobile number'),
    address1: Yup.string().required('Please enter your address1'),
    AMCstart: Yup.date().required('Please enter your AMC start date'),
    AMCend: Yup.date().required('Please enter your AMC end date'),
})
