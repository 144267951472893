/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {UserTransactions} from '../../../_metronic/partials/widgets/tables/UserTransactions'
import {UserChargingTransactions} from '../../../_metronic/partials/widgets/tables/UserChargingTransactions'
import {WalletTransactionsOfUser} from '../../../_metronic/partials/widgets/tables/WalletTransactionsOfUser'
import {UsersCars} from '../../../_metronic/partials/widgets/tables/UsersCars'
import AddFundstoWallet from '../../../_metronic/partials/widgets/tables/AddFundsToWalletModal'
import {getRequest} from '../Helpers/Requests'
import UserDetailsRfIdTags from '../../modules/Evusers/EV/components/UserDetailsRfIdTags'
import UserStation from '../../modules/Evusers/EV/components/UserStation'
import {useAuth} from '../../modules/auth'

export interface UserDetails {
  id: any
  firstName: String
  email: String
  mobileNum: String
  role: String
  activestatus: String
  tenantid: String
  signUp: String
  language: String
  PAN: String
  gstNum: String
  state: String
  vehicle: String
}

export const Users = {
  heading: 'Users Details',
  username: 'User Name',
  email: 'Contact',
  wallet: 'Wallet Balance',
  Rfid: 'Rfid',
  role: 'Role',
  activestatus: 'Active Status',
  tenantid: 'Tenant Id',
  signUp: 'SignUp',
  language: 'Language',
  PAN: 'PAN Number',
  GST: 'GST Number',
  state: 'State',
  vehicle: 'Vehicle',
}

const pagePermissions = {
  CreateTag: 'CreateRFIDtag',
  EditTag: 'EditRFIDtag',
  DeleteTag: 'DeleteRFIDtag',
}

const UserDetailsHeader = () => {
  let {pointId} = useParams()
  const [DetailsUser, setDetailsUser] = useState<any>(null)
  const [walletId, setWalletId] = useState()
  const {permissions} = useAuth()

  const getUserDetails = async () => {
    try {
      getRequest(`/ev/users/${pointId}`).then((res: any) => {
        console.log('at user details', res.data.data)
        setDetailsUser(res.data.data.EvUser)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  return (
    <div>
      {/* <div className='text-end'>
        <Link
          to='/Evusers/EV/UserList'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div> */}
      <div className='card mb-5  mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header '>
          <div className='d-flex justify-content-center '>
            <h3 className='card-title'>{Users.heading}</h3>
          </div>
          <div className='d-flex justify-content-center justify-content-end '>
            <span className='card-title'>
              <a
                className='btn btn-icon btn-bg-light btn-active-color-success btn-sm bg-light-success ms-3'
                data-bs-toggle='modal'
                data-bs-target='#wallet_funds_add'
                onClick={() => {
                  setWalletId(DetailsUser?.wallet?.id)
                }}
              >
                <KTIcon iconName='plus-circle' className='fs-3' />
              </a>
            </span>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.username}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.firstName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.email}</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                {DetailsUser?.email && DetailsUser?.mobileNumber
                  ? `${DetailsUser.email} - ${DetailsUser.mobileNumber}`
                  : DetailsUser?.email || DetailsUser?.mobileNumber || ''}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.wallet}</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>₹ {DetailsUser?.wallet?.balance}</span>
              {/* <span className='badge badge-success'>Verified</span> */}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {Users.activestatus}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>
            <div className='col-lg-8'>
              {DetailsUser?.status === 'Active' ? (
                <span className='badge badge-light-success'>{DetailsUser?.status}</span>
              ) : (
                <span className='badge badge-light-danger'>{DetailsUser?.status}</span>
              )}
            </div>
          </div>
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.tenantid}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                 Tenant Id ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.signUp}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                Sign Up ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.language}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                 Language ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.PAN}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
          PAN NUMBER ?
              </span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.GST}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.gstNum}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.state}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.state}</span>
            </div>
          </div> */}
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{Users.vehicle}</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{DetailsUser?.vehicle}</span>
            </div>
          </div> */}
        </div>
      </div>
      <div className='mt-10'>
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-4'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
              Payment Transactions
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
              Charging Transactions
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_6'>
              Wallet Transactions
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_7'>
              Cars
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_8'>
              RFID Tags
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_9'>
              Stations
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
            <UserTransactions userId={pointId} className='mb-5 mb-xl-8' />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
            <UserChargingTransactions userId={pointId} className='mb-5 mb-xl-8' />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_6' role='tabpanel'>
            <WalletTransactionsOfUser userId={pointId} className='mb-5 mb-xl-8' />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_7' role='tabpanel'>
            <UsersCars userId={pointId} className='mb-5 mb-xl-8' cars={DetailsUser?.vehicles} />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_8' role='tabpanel'>
            {permissions.includes(pagePermissions.CreateTag) && (
              <div className='d-flex justify-content-end'>
                <Link to={`/AddTag/${pointId}`} className='btn btn-sm btn-light-success'>
                  <KTIcon iconName='plus' className='fs-2' />
                  RFID TAG
                </Link>
              </div>
            )}
            <UserDetailsRfIdTags tags={DetailsUser?.tag} />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_9' role='tabpanel'>
            <UserStation
              className='mb-5 mb-xl-8'
              stationPrice={DetailsUser?.userPrice}
              userId={pointId}
              userDetails={getUserDetails}
            />
          </div>
        </div>
      </div>
      <AddFundstoWallet walletId={walletId} getWallets={getUserDetails} />
    </div>
  )
}

export default UserDetailsHeader
