/* eslint-disable */

import {Field, Formik, Form} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {newScheduleValidation} from '../pages/js/newScheduleValidation'
import toast,{Toaster} from 'react-hot-toast'
import {useState, useEffect} from 'react'
import {getRequest, putRequest} from './Helpers/Requests'
import {scheduleReportInitial} from './Helpers/AllInitialValues'

const EditSchedule = () => {
  const {pointId} = useParams()
  const [operators, setOperators] = useState<any>([])
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState(scheduleReportInitial)
  const today = new Date().toISOString().split('T')[0]

  // function to get all operators
  const getAllOperators = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/cms/users/?field=OperatorName,id`)
      if (res && res.data) {
        setOperators(res.data.data.CmsUsers)
      }
    } catch (error: any) {
      console.log(error.messsage)
    }
  }

  // function to get report by Id
  const getUserObject = async () => {
    try {
      const res = await getRequest(`${process.env.REACT_APP_API_URL}/report/${pointId}`)
      if (res && res.data) {
        setInitialValues(res.data.data.report)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // function for handling put request
  const HandleSubmit = async (values: any) => {
    try {
      const res = await putRequest(`${process.env.REACT_APP_API_URL}/report/${pointId}`, values)
      if (res) {
        toast.success('Updated Successfully ✅')
        setTimeout(() => {
          navigate('/report/data/Schedule')
        }, 1000)
      }
    } catch (error: any) {
      toast.error('Invalid Data Check The Data ❌')
      console.log(error.message)
    }
  }

  useEffect(() => {
    getUserObject()
    getAllOperators()
  }, [])

  return (
    <div>
    
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>EDIT A SCHEDULE</h3>
        </div>
        <div className='card-body'>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={newScheduleValidation}
            onSubmit={HandleSubmit}
          >
            {({errors, values}) => (
              <Form>
                <div className='row pt-5'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='reportName'
                        value={values.reportName}
                      >
                        <option value=''>Select report name</option>
                        <option value='Settlement Report'>Settlement Report</option>
                        <option value='Transaction Summary'>Transaction Summary</option>
                        <option value='Charger Report'>Charger Report</option>
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.reportName && errors.reportName}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='reportInterval'
                      >
                        <option value=''>Select report interval</option>
                        <option value='Monthly'>Monthly</option>
                        <option value='Daily'>Daily</option>
                        <option value='Weekly'>Weekly</option>
                        <option value='Quarterly'>Quarterly</option>
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.reportInterval && errors.reportInterval}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        as='select'
                        className='form-select'
                        aria-label='Select example'
                        name='operatorId'
                      >
                        <option value=''>Select operator</option>
                        {operators.map((e, i) => {
                          return (
                            <option key={i} value={e.id}>
                              {e.OperatorName}
                            </option>
                          )
                        })}
                      </Field>
                      <span className='text-danger fs-5'>
                        {errors.operatorId && errors.operatorId}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        aria-label='Select example'
                        placeholder='To recepient'
                        name='toRecepient'
                      />
                      <span className='text-danger fs-5'>
                        {errors.toRecepient && errors.toRecepient}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-10'>
                      <Field
                        type='text'
                        className='form-control'
                        aria-label='Select example'
                        placeholder='Cc recepient'
                        name='ccRecepient'
                      />
                      <span className='text-danger fs-5'>
                        {errors.ccRecepient && errors.ccRecepient}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='date'
                        className='form-select'
                        aria-label='Select example'
                        min={today}
                        name='startDate'
                        value={values.startDate}
                      />
                      <label className='fw-medium ms-1 pt-1'>Start Date</label>
                      <br />
                      <span className='text-danger fs-5'>
                        {errors.startDate && errors.startDate}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='date'
                        className='form-select'
                        aria-label='Select example'
                        min={today}
                        name='endDate'
                      />
                      <label className='fw-medium ms-1 pt-1'>End Date</label>
                      <br />
                      <span className='text-danger fs-5'>{errors.endDate && errors.endDate}</span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-10'>
                      <Field
                        type='date'
                        className='form-select'
                        aria-label='Select example'
                        min={today}
                        name='nextExecutionDate'
                      />
                      <label className='fw-medium ms-1 pt-1'>Next Execution Date</label>
                      <br />
                      <span className='text-danger fs-5'>
                        {errors.nextExecutionDate && errors.nextExecutionDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='mb-10'>
                    <button type='submit' className='btn btn-light-success'>
                      Update
                    </button>
                  </div>
                  <div className='mb-10 ms-2'>
                    <button
                      type='button'
                      className='btn btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      Cancel
                    </button>
                    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                      <div className='modal-dialog'>
                        <div className='modal-content'>
                          <div className='modal-header bg-success'>
                            <h5 className='modal-title text-white'>Confirmation message</h5>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                            >
                              x
                            </div>
                          </div>
                          <div className='modal-body'>
                            <p>Your data is not saved, Are you sure to exit ?</p>
                          </div>
                          <div className='modal-footer'>
                            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                              No
                            </button>
                            <button
                              type='button'
                              data-bs-dismiss='modal'
                              onClick={() => navigate('/report/data/Schedule')}
                              className='btn btn-success'
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditSchedule
