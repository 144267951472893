import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {getRequest, putRequest} from '../Helpers/Requests'
import * as Yup from 'yup'
import {ErrorMessage, Field, Formik, Form} from 'formik'

import toast from 'react-hot-toast'

const settleAmountValidation = Yup.object().shape({
  month: Yup.string().required('Month is required'),
  totalAmount: Yup.number().required('Please mention the amount'),
})

function SettlementModal({settlementId, getSettlements}) {
  const [initialValues, setInitialValues] = useState({
    month: '',
    totalAmount: 0,
    pendingAmount: 0,
    settledAmount: 0,
    settleAmount: 0,
    settledBy: '',
    status: 0,
  })

  const handleSubmit = async (values, {resetForm}) => {
    try {
      const res = await putRequest('/settlements/' + settlementId, values)
      console.log(res)
      if (res?.status === 200) {
        toast.success('Settlement done Successfully')
        getSettlements()
        resetForm()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: any) => {
    const monthNumber = e.target.value
    console.log(monthNumber)
  }

  useEffect(() => {
    const getSettlementById = async () => {
      try {
        const res = await getRequest('/settlements/' + settlementId)
        console.log(res)
        if (res?.status === 200) {
          setInitialValues({
            ...initialValues,
            ...res?.data?.data,
            pendingAmount: res.data.data.totalAmount - res.data.data.settledAmount,
            settleAmount: res.data.data.totalAmount - res.data.data.settledAmount,
          })
          console.log(res.data.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getSettlementById()
  }, [settlementId])

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='settle_amount'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content shadow-none'>
            <div className='modal-header'>
              <h5 className='modal-title'>Settle Amount</h5>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={initialValues}
                validationSchema={settleAmountValidation}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({setFieldValue}) => (
                  <Form>
                    <div>
                      <div className='row'>
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Month</label>
                          <Field
                            as='select'
                            placeholder='Date'
                            name='month'
                            className='form-control'
                            onChange={handleChange}
                            value={initialValues.month}
                            disabled={initialValues.month ? true : false}
                          >
                            <option value={''}>Select Month</option>
                            <option value='January'>January</option>
                            <option value='February'>February</option>
                            <option value='March'>March</option>
                            <option value='April'>April</option>
                            <option value='May'>May</option>
                            <option value='June'>June</option>
                            <option value='July'>July</option>
                            <option value='August'>August</option>
                            <option value='September'>September</option>
                            <option value='October'>October</option>
                            <option value='November'>November</option>
                            <option value='December'>December</option>
                          </Field>
                          <ErrorMessage name='date' component='div' />
                        </div>

                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Total Amount</label>
                          <Field
                            name='totalAmount'
                            className='form-control'
                            placeholder='Total Amount'
                            disabled={initialValues.totalAmount ? true : false}
                          />
                          <ErrorMessage name='totalAmount' component='div' />
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Settled Amount</label>
                          <Field
                            name='settledAmount'
                            className='form-control'
                            placeholder='Settled Amount'
                          />
                          <ErrorMessage name='settledAmount' component='div' />
                        </div>
                        {initialValues.pendingAmount > 0 && (
                          <div className='col-md-6 mt-3'>
                            <label className='form-label'>Pending Amount</label>
                            <Field
                              name='pendingAmount'
                              className='form-control'
                              placeholder='Pending Amount'
                            />
                            <ErrorMessage name='pendingAmount' component='div' />
                          </div>
                        )}
                        {initialValues.pendingAmount > 0 && (
                          <div className='col-md-6 mt-3'>
                            <label className='form-label'>To be Settled Amount</label>
                            <Field
                              name='settleAmount'
                              className='form-control'
                              placeholder='Amount'
                            />
                            <ErrorMessage name='settleAmount' component='div' />
                          </div>
                        )}
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Settled By</label>
                          <Field
                            name='settledBy'
                            className='form-control'
                            placeholder='Name of Settler'
                          />
                          <ErrorMessage name='settledBy' component='div' />
                        </div>
                        <div className='col-md-6 mt-11 justify-content-end'>
                          {initialValues.status === 1 ? (
                            <span className='btn btn-success'>Success</span>
                          ) : (
                            <span className='btn btn-warning'>Pending</span>
                          )}
                        </div>
                      </div>
                      {initialValues.pendingAmount > 0 && (
                        <div className='modal-footer mt-3'>
                          <button
                            type='button'
                            className='btn btn-light-danger'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <button
                            type='submit'
                            className='btn btn-light-success'
                            data-bs-dismiss='modal'
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettlementModal
