import React from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'
const validationSchema = Yup.object().shape({
  resetType: Yup.string().required('Reset Type is required'),
})

function ResetDevice({stationId}) {
  const initialValues = {
    resetType: '',
  }
  const handleSubmit = async (values, {resetForm}) => {
    try {
      const res = await postRequest('/ocpp/remote/reset-device/' + stationId, values)
      if (res && res.data) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='reset_device'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Reset Device</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Select Type of Reset</label>
                        <Field as='select' name='resetType' className='form-control'>
                          <option value=''>Type of Reset</option>
                          <option value='Hard'>HARD</option>
                          <option value='Soft'>SOFT</option>
                        </Field>
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetDevice
