import {useEffect, useState} from 'react'
import {KTSVG} from '../../helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import {putRequest} from '../../../app/pages/Helpers/Requests'

interface InitialValues {
  key: string
  type: string
  platform: string
  description: string
  status: string
  value: string | number
}

const AdminControllModal = ({selectedItem, fetchData}: any) => {
  const [initialValues, setInitialValues] = useState<InitialValues>({
    key: '',
    type: '',
    platform: '',
    description: '',
    status: '',
    value: '',
  })

  useEffect(() => {
    if (selectedItem) {
      setInitialValues(selectedItem)
    }
  }, [selectedItem])

  const validationSchema = Yup.object().shape({
    key: Yup.string().required('Key is required'),
    platform: Yup.string().required('Platform is required'),
    description: Yup.string().required('Description is required'),
    value: Yup.string().required('Value is required'),
  })

  const handleSubmit = async (values: InitialValues, {resetForm}: {resetForm: () => void}) => {
    try {
      let updatedValues = {
        value: values.value,
        key: values.key,
        description: values.description,
      }
      const res = await putRequest(`/admin-option/${selectedItem?.id}`, updatedValues)
      if (res && res.status === 200) {
        toast.success('Admin Option Updated Successfully!')
        fetchData()
        resetForm()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response?.data?.message)
    }
  }

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='edit_controll'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header bg-success'>
              <h5 className='modal-title text-white'>Edit Option</h5>
              <button
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({handleSubmit}) => (
                  <Form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='Formik-label'>Option</label>
                        <Field name='key' disabled className='form-control' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Platform</label>
                        <Field as='select' name='platform' className='form-control' disabled>
                          <option value='' hidden>
                            Select Platform
                          </option>
                          <option value='IOS'>IOS</option>
                          <option value='ANDROID'>Android</option>
                          <option value='WEB'>Web</option>
                          <option value='CMS'>CMS</option>
                          <option value='SERVER'>SERVER</option>
                          <option value='APP'>APP</option>
                          <option value='SERVER'>ALL</option>
                        </Field>
                        <ErrorMessage name='platform' component='div' className='text-danger' />
                      </div>
                      {selectedItem?.type === 'multiple-choice' ? (
                        <div className='col-md-12 mt-3'>
                          <label className='form-label'>Value</label>
                          <Field as='select' name='value' className='form-control'>
                            <option value='' hidden>
                              Select Value
                            </option>
                            {selectedItem?.valueChoices?.map((choice: string, index: number) => {
                              return (
                                <option key={index} value={choice}>
                                  {choice}
                                </option>
                              )
                            })}
                          </Field>
                          <ErrorMessage name='platform' component='div' className='text-danger' />
                        </div>
                      ) : (
                        <div className='col-md-12 mt-3'>
                          <label className='Formik-label'>Value</label>
                          <Field name='value' type={selectedItem?.type} className='form-control' />
                        </div>
                      )}
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Description</label>
                        <Field
                          as='textarea'
                          name='description'
                          rows={2}
                          className='form-control'
                          maxLength={selectedItem?.descriptionLength}
                        />
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminControllModal
