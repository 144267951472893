import {useEffect, useState} from 'react'
import {KTSVG} from '../../helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import {putRequest} from '../../../app/pages/Helpers/Requests'

interface InitialValues {
  gstNum: string
}

const UpdateGST = ({selectedItem, fetchData}: any) => {
  const [initialValues, setInitialValues] = useState<InitialValues>({
    gstNum: '',
  })

  useEffect(() => {
    if (selectedItem) {
      setInitialValues({gstNum: selectedItem.gstNum || ''})
    }
  }, [selectedItem])

  const validationSchema = Yup.object().shape({
    gstNum: Yup.string()
      .required('GST Number is required')
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        'Invalid GST Number format'
      ),
  })

  const handleSubmit = async (values: InitialValues, {resetForm}: {resetForm: () => void}) => {
    try {
      const updatedValues = {gstNum: values.gstNum}
      const res = await putRequest(`/state-gst/${selectedItem?.id}`, updatedValues)

      if (res && res.status === 200) {
        toast.success('GST Number Updated Successfully!')
        fetchData()
        resetForm()
      }
    } catch (error: any) {
      console.error('Error updating GST number:', error)
      toast.error(error.response?.data?.message || 'Failed to update GST Number.')
    }
  }

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='edit_gst'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header bg-success'>
              <h5 className='modal-title text-white'>Update GST Number</h5>
              <button
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({handleSubmit}) => (
                  <Form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>GST Number</label>
                        <Field name='gstNum' className='form-control' />
                        <ErrorMessage name='gstNum' component='div' className='text-danger' />
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateGST
