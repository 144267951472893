import React, {useEffect, useState} from 'react'
import {StatisticsWidget5} from '../../../../../_metronic/partials/widgets'
import {TableWidgetDeep1} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep1'
import {TableWidgetDeep2} from '../../../../../_metronic/partials/widgets/tables/TableWidgetDeep2'
import {getRequest} from '../../../../pages/Helpers/Requests'
import {DashboardPaymentTransactionTable} from '../../../../../_metronic/partials/widgets/tables/DashboardPaymentTransactionTable'
import {DashboardTransactionTable} from '../../../../../_metronic/partials/widgets/tables/DashboardTransactionTable'
import {useAuth} from '../../../auth'

const dashBoardPermissions = {
  dashboardView: 'ViewDashboard',
}

let deviceTransPermissions = {
  view: 'ViewDeviceTransactions',
  global: 'GlobalDeviceTransaction',
}

let transactionPermissions = {
  view: 'ViewTransactions',
}
const Dashboard = () => {
  const [analytics, setAnalytics] = useState<any>()
  const [requests, setRequests] = useState([])
  const [amenityRequests, setAmenityRequests] = useState([])
  const [displayingOptions, setDisplayingOptions] = useState({
    totalUsers: true,
    todayRegistrations: true,
    totalTransactions: true,
    todayTransactions: true,
    totalStations: true,
    availableConnectors: true,
    engagedConnectors: true,
    faultedConnectors: true,
    paymentTransactionTable: true,
  })

  const {permissions, currentUser} = useAuth()
  console.log(permissions)
  const getDashboardAnalytics = async () => {
    try {
      const res = await getRequest('/dashboard/dashboard-analytics')
      console.log(res)
      if (res?.status === 200) {
        setAnalytics(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllRequests = async () => {
    try {
      const res = await getRequest('/tariff-change/')
      if (res?.status === 200) {
        setRequests(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllAmenityChangeRequests = async () => {
    try {
      const res = await getRequest('/amenity-change/')
      if (res?.status === 200) {
        const dataWithCounts = res.data.data.map((item) => {
          const countItem = res.data.counts.find((countItem) => item.stationId === countItem.id)

          return {
            ...item,
            newCount: countItem ? countItem.newCount : 0,
            oldCount: countItem ? countItem.oldCount : 0,
            delCount: countItem ? countItem.delCount : 0,
          }
        })
        setAmenityRequests(dataWithCounts)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDashboardAnalytics()
    getAllRequests()
    getAllAmenityChangeRequests()
  }, [])

  useEffect(() => {
    if (!permissions.includes(dashBoardPermissions.dashboardView)) {
      setDisplayingOptions({
        ...displayingOptions,
        totalStations: false,
        totalUsers: false,
        todayRegistrations: false,
        engagedConnectors: false,
        paymentTransactionTable: false,
      })
    }
  }, [currentUser])

  return (
    <>
      {/* begin::Row */}
      {/* <h1 className='fw-bold' style={{fontSize: '32px'}}>
        Complete Analytics of Charging Stations
      </h1>
      <div className='row g-5 g-xl-10 mb-xl-10'>
        <div className='row g-5 g-xl-10'>
          {currentUser?.group === '1' && connectors.length > 0 && (
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12' id='faulted'>
              <TableWidgetDeep7 TCD={Deepdive7} className='mb-5 mb-xl-8' connectors={connectors} />
            </div>
          )}
        </div>
      </div> */}
      <div className='row g-5 g-xl-8'>
        {displayingOptions.totalUsers && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8 text-white'
              svgIcon='people'
              color='info'
              iconColor='white'
              title={`${analytics?.totalUsersCount}`}
              description='Total Users Count'
              isClickable={true}
              linkTo='/UserManagment/User/CmsUsers'
            />
          </div>
        )}

        {displayingOptions.totalTransactions && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='chart-simple-3'
              color='dark'
              iconColor='white'
              title={`${analytics?.totalTransactionsCount}`}
              titleColor='white'
              description='Total Transactions'
              descriptionColor='white'
              isClickable={true}
              linkTo='/Evusers/EV/walletTransactions'
            />
          </div>
        )}
        {displayingOptions.todayTransactions && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='ranking'
              color='warning'
              iconColor='white'
              title={`${analytics?.todayTransactionsCount}`}
              titleColor='white'
              description="Today's Transactions"
              descriptionColor='white'
            />
          </div>
        )}

        {displayingOptions.todayRegistrations && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='user'
              color='primary'
              iconColor='white'
              title={`${analytics?.todayRegisteredUsersCount}`}
              titleColor='white'
              description='Users Registered Today'
              descriptionColor='white'
              isClickable={false}
            />
          </div>
        )}

        {displayingOptions.totalStations && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='electricity'
              color='white'
              iconColor='dark'
              title={`${analytics?.totalStationsCount}`}
              description='Total Chargers'
              isClickable={false}
            />
          </div>
        )}

        {displayingOptions.availableConnectors && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='electricity'
              color='success'
              iconColor='white'
              title={`${analytics?.availableStationCount}`}
              titleColor='white'
              description='Available Connectors'
              descriptionColor='white'
              isClickable={false}
            />
          </div>
        )}

        {displayingOptions.engagedConnectors && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='electricity'
              color='secondary'
              iconColor='dark'
              title={`${analytics?.engagedStationCount}`}
              titleColor='dark'
              description='Engaged Connectors'
              descriptionColor='dark'
              isClickable={false}
            />
          </div>
        )}
        {displayingOptions.faultedConnectors && (
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='electricity'
              color='danger'
              iconColor='white'
              title={`${analytics?.faultedStationCount}`}
              titleColor='white'
              description='Faulted Connectors'
              descriptionColor='white'
              isClickable={true}
              linkTo='/faulted/connector'
            />
          </div>
        )}
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className=' g-xl-10 mb-5 mb-xl-10'>
          {/* <div className='col-md-6 col-lg-12 col-xl-6 col-xxl-6'>
            <DashboardTransactionTable
              transactions={analytics?.recentDeviceTransactions}
              className='mb-5 mb-xl-8'
            />
          </div>

          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
            <DashboardPaymentTransactionTable
              transactions={analytics?.recentPaymentTransactions}
              className='mb-5 mb-xl-8'
            />
          </div> */}
          {/* {displayingOptions.paymentTransactionTable ? (
            <div className='row'>
              <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                <DashboardTransactionTable
                  transactions={analytics?.recentDeviceTransactions}
                  className='mb-5 mb-xl-8'
                />
              </div>

              <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                <DashboardPaymentTransactionTable
                  transactions={analytics?.recentPaymentTransactions}
                  className='mb-5 mb-xl-8'
                />
              </div>
            </div>
          ) : (
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
              <DashboardTransactionTable
                transactions={analytics?.recentDeviceTransactions}
                className='mb-5 mb-xl-8'
              />
            </div>
          )} */}

          <div className='row'>
            {(permissions.includes(deviceTransPermissions.global) ||
              permissions.includes(deviceTransPermissions.view)) && (
              <div
                className={
                  permissions.includes(transactionPermissions.view)
                    ? 'col-md-6 col-lg-6 col-xl-6 col-xxl-6'
                    : 'col-md-12 col-lg-12 col-xl-12 col-xxl-12'
                }
              >
                <DashboardTransactionTable
                  transactions={analytics?.recentDeviceTransactions}
                  className='mb-5 mb-xl-8'
                />
              </div>
            )}
            {permissions.includes(transactionPermissions.view) && (
              <div
                className={
                  permissions.includes(deviceTransPermissions.global) ||
                  permissions.includes(deviceTransPermissions.view)
                    ? 'col-md-6 col-lg-6 col-xl-6 col-xxl-6'
                    : 'col-md-12 col-lg-12 col-xl-12 col-xxl-12'
                }
              >
                <DashboardPaymentTransactionTable
                  transactions={analytics?.recentPaymentTransactions}
                  className='mb-5 mb-xl-8'
                />
              </div>
            )}
          </div>
        </div>

        {requests.length > 0 && currentUser?.group === '1' && (
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <TableWidgetDeep1 requests={requests} className='mb-5 mb-xl-8' />
          </div>
        )}

        {amenityRequests.length > 0 && currentUser?.group === '1' && (
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <TableWidgetDeep2 sessions={amenityRequests} className='mb-5 mb-xl-8' />
          </div>
        )}

        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep3 chargingchargebox={Deepdive3} className='mb-5 mb-xl-8' />
        </div> */}

        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep4 available={Deepdive4} className='mb-5 mb-xl-8' />
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep5 faculty={Deepdive5} className='mb-5 mb-xl-8' />
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-10'>
          <TableWidgetDeep6 customer={Deepdive6} className='mb-5 mb-xl-8' />
        </div>

         */}

        {/*begin:End */}
      </div>
      {/* end::Row */}
    </>
  )
}

export default Dashboard
