import React from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import toast from 'react-hot-toast'
import {postRequest} from '../../../app/pages/Helpers/Requests'
import {KTSVG} from '../../helpers'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Amenity name is required'),
  type: Yup.string().required('Type of amenity is required'),
})

function AddAmenity({getAmenities}) {
  const initialValues = {
    name: '',
    type: '',
  }
  const handleSubmit = async (values, {resetForm}) => {
    const formData = new FormData()
    formData.append('image', values.image)
    formData.append('name', values.name)
    formData.append('type', values.type)
    try {
      const res = await postRequest('/amenities/', formData)
      if (res && res.data) {
        getAmenities()
        toast.success(res.data.message)
        resetForm()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='add_amenity'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Add Amenity</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Name</label>
                        <Field placeholder='Amenity Name' name='name' className='form-control' />
                        <ErrorMessage name='name' component='div' className='text-danger' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Type</label>
                        <Field as='select' name='type' className='form-control'>
                          <option value=''>Select Type of Amenity</option>
                          <option value='Complimentary Services'>Complimentary Services</option>
                          <option value='Near By Services'>Near By Services</option>
                        </Field>
                        <ErrorMessage name='type' component='div' className='text-danger' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Image of Amenity</label>
                        <input
                          type='file'
                          name='image'
                          className='form-control'
                          onChange={(e: any) => {
                            setFieldValue('image', e.target.files[0])
                          }}
                        />
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAmenity
