import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import toast from 'react-hot-toast'
import Select from 'react-select'
import {KTSVG} from '../../../_metronic/helpers'
import {getRequest, putRequest} from '../Helpers/Requests'

const MultiSelect = ({values, initialValues, setValues}) => {
  const [data, setData] = useState([])
  const onSelect = (data) => {
    setValues(data)
  }
  useEffect(() => {
    setData(values)
  }, [values])

  return (
    <Select
      closeMenuOnSelect={true}
      name='connectorType'
      isMulti
      value={data}
      options={initialValues || []}
      onChange={onSelect}
    />
  )
}

export default function EditCar({id, getAllCar, carbrands}) {
  const [initialValues, setInitialValues] = useState({
    name: '',
    brandId: '',
    carType: '',
    image: null,
    connectorType: [],
  })
  const [connectorTypes, setConnectorTypes] = useState([])

  const getConnectorTypes = async () => {
    try {
      const res = await getRequest(`/connector-type`)
      if (res && res.data) {
        setConnectorTypes(res.data?.connectorTypes)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getCarById = async () => {
    try {
      const res = await getRequest('/car/' + id)
      if (res) {
        setInitialValues({
          ...res.data?.car,
          image: null,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCarById()
  }, [id])

  const handleSubmit = async (values, {resetForm}) => {
    const formData = new FormData()

    formData.append('name', values.name)
    formData.append('brandId', values.brandId)
    formData.append('carType', values.carType)
    if (values.image) {
      formData.append('image', values.image)
    }
    if (values.connectorType) {
      values.connectorType.forEach((item: any) => {
        formData.append('connectorType', item.id)
      })
    }

    try {
      const res = await putRequest(`/car/${id}`, formData)
      if (res && res.data) {
        toast.success(res.data.message)
        resetForm()
        getAllCar()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response?.data?.message)
    }
  }

  useEffect(() => {
    getConnectorTypes()
  }, [])

  return (
    <div className='modal fade' tabIndex={-1} id='edit_car'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Edit Car</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Car Name</label>
                      <Field
                        placeholder='Car Name'
                        name='name'
                        className='form-control'
                        type='text'
                      />
                      <ErrorMessage name='name' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Car Type</label>
                      <Field as='select' name='carType' className='form-control'>
                        <option value=''>Select Car Type</option>
                        <option value='SUV'>SUV</option>
                        <option value='MUV'>MUV</option>
                        <option value='HatchBack'>HatchBack</option>
                        <option value='Sedan'>Sedan</option>
                        <option value='Coupes'>Coupes</option>
                        <option value='Convertible'>Convertible</option>
                      </Field>
                      <ErrorMessage name='carType' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Car Brand</label>
                      <Field as='select' name='brandId' className='form-control'>
                        <option value=''>Select Car Brand</option>
                        {carbrands?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.brandName}
                            </option>
                          )
                        })}
                      </Field>
                      <ErrorMessage name='carBrand' component='div' className='text-danger' />
                    </div>

                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Port Type</label>
                      {/* <Select
                        closeMenuOnSelect={true}
                        name='connectorType'
                        isMulti
                        value={values?.connectorType?.map((type: any) => ({
                          value: type?.id,
                          label: type?.name,
                        }))}
                        options={connectorTypes?.map((item: any) => ({
                          value: item?.id,
                          label: item?.name,
                          key: item?.id,
                        }))}
                        onChange={(selectedOptions: any) => {
                          const selectedValues = selectedOptions.map((option: any) => option?.value)
                          setFieldValue('connectorType', selectedValues)
                        }}
                      /> */}
                      <MultiSelect
                        values={values?.connectorType?.map((type: any) => ({
                          value: type?.id,
                          label: type?.name,
                        }))}
                        initialValues={connectorTypes?.map((item: any, index: number) => ({
                          value: item?.id,
                          label: item?.name,
                          key: index,
                        }))}
                        setValues={(data) => {
                          console.log('selected data', data)
                          const updatedData = data.map((item) => item?.value)

                          const filtered = connectorTypes.filter((item: any) => {
                            return updatedData.includes(item?.id)
                          })
                          setFieldValue('connectorType', filtered)
                        }}
                      />

                      <ErrorMessage name='connectorType' component='div' className='text-danger' />
                    </div>

                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Car image</label>
                      <input
                        placeholder='Image'
                        type='file'
                        className='form-control'
                        onChange={(event: any) => {
                          setFieldValue('image', event.target.files[0])
                        }}
                      />
                      <ErrorMessage name='image' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
