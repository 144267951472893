/* eslint-disable  */
import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'

type Props = {
  className: string
}

export interface cmsUpdate {
  id: any
  OperatorID: String
  OperatorName: String
  group: String
  email: String
  country: String
  state: String
  city: String
  pincode: String
  countryCode: String
  mobileNumber: String
  address1: String
  address2: String
  AMCstart: String
  AMCend: String
}

export const CmsTable = {
  username: 'UserName',
  email: 'Email Id',
  mobilenumber: 'Mobile Number',
  status: 'Status',
  group: 'Group Type',
}

const TableRoleUserMapping: React.FC<Props> = ({ className }) => {
  const [CMSUsers, setCMSUsers] = useState<cmsUpdate[]>([])
  const [groupType, setGroupType] = useState('All')

  const navigate = useNavigate()
  const { pointId } = useParams()
  const userData =
    groupType == 'All'
      ? CMSUsers
      : CMSUsers.filter((item) => item.group.toLowerCase().trim() == groupType.toLowerCase().trim())

  interface AuthData {
    token: string
  }
  const storedDataString: string | null = localStorage.getItem('kt-auth-react-v')
  const { token }: AuthData = storedDataString ? JSON.parse(storedDataString) : { token: '' }
  const handlePermissionsAssign = (id) => {
    const data = { permissionId: pointId, userId: id }
    const confirm = window.confirm('Do you sure want to Assign this role to selected user')
    if (confirm) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/permissions/assign/user`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res)
          alert('role assigned to user successfully')
        })
        .catch((err) => {
          alert('User Already has the permission')
        })
    }
  }
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/cms/users/?includes=role&field=email,mobileNumber,status,OperatorName,id`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCMSUsers(res.data.data.CmsUsers)
      })
      .catch((err) => console.log(err))
  }, [])

  const handleOnChange = (e) => {
    e.preventDefault()
    setGroupType(e.target.value)
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Roles Mapping Operators</span>
        </h3>
        <div className='card-toolbar d-flex gap-5'>
          {/* begin::Menu */}
          <div>
            <select className='form-select' onChange={handleOnChange}>
              <option value='All'>ALL</option>
              <option value='Admin'>ADMIN</option>
              <option value='CPO'>CPO</option>
              <option value='gfgffjh'>gfgffjh</option>
              <option value='Operator'>Operator</option>
              <option value='SUBCPO'>SubCPO</option>
              <option value='SubOperator'>SubOperator</option>
              <option value='Testing'>Testing</option>
            </select>
          </div>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-2'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div> */}
                </th>
                <th className='min-w-210px'>{CmsTable.username}</th>
                <th className='min-w-210px'>{CmsTable.email}</th>
                <th className='min-w-210px'>{CmsTable.mobilenumber}</th>
                <th className='min-w-210px'>{CmsTable.status}</th>
                <th className='min-w-210px'>{CmsTable.group}</th>
                <th className='min-w-210px text-end'>Assign</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {userData.map((item: cmsUpdate) => {
                return (
                  <tr key={item.id}>
                    <td></td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={'/CMSsinglepage/' + item.id}
                            className='text-dark fw-bold text-hover-success fs-6'
                          >
                            {item.OperatorName}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.email}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.mobileNumber}
                      </a>
                    </td>
                    <td>
                      <a href='#' className=' fw-bold d-block fs-6 badge badge-light-success'>
                        Active
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold d-block fs-6'>
                        {item.group}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                          onClick={() => {
                            handlePermissionsAssign(item.id)
                          }}
                        >
                          <KTIcon iconName='message-add' className='fs-5 w-50' />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TableRoleUserMapping }
