import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {postRequest} from '../Helpers/Requests'
import * as Yup from 'yup'
import {ErrorMessage, Field, Formik, Form} from 'formik'

import toast from 'react-hot-toast'

const addExpenseValidation = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  particular: Yup.string().required('Content for Particular Field is required'),
  amount: Yup.number().required('Please mention the amount'),
})

function AddExpenseModal({stationRef}) {
  let initialValues = {
    date: '',
    particular: '',
    amount: 0,
  }

  const handleSubmit = async (values, {resetForm}) => {
    try {
      const formData = new FormData()
      formData.append('particular', values.particular)
      formData.append('amount', values.amount)
      formData.append('file', values.ack)
      formData.append('date', values.date)
      const res = await postRequest('/expenses/' + stationRef, formData)
      if (res?.status === 200) {
        toast.success('Successfully created an expense')
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.message)
    }
  }

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='add_expense'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content shadow-none'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Expense</h5>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={initialValues}
                validationSchema={addExpenseValidation}
                onSubmit={handleSubmit}
              >
                {({setFieldValue}) => (
                  <Form>
                    <div>
                      <div className='row'>
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Date</label>
                          <Field
                            type='date'
                            placeholder='Date'
                            name='date'
                            className='form-control'
                          />
                          <ErrorMessage name='date' component='div' />
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Particular</label>
                          <Field
                            name='particular'
                            className='form-control'
                            placeholder='Particular'
                          />
                          <ErrorMessage name='particular' component='div' />
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Amount</label>
                          <Field name='amount' className='form-control' placeholder='Amount' />
                          <ErrorMessage name='amount' component='div' />
                        </div>
                        <div className='col-md-6 mt-3'>
                          <label className='form-label'>Acknowledgement</label>
                          <input
                            className='form-control'
                            type='file'
                            id='acknowledgement'
                            onChange={(event: any) => {
                              setFieldValue('ack', event.target.files[0])
                              const maxFileSize = 1024 * 1024 * 2
                              if (event.target.files[0].size > maxFileSize) {
                                setFieldValue('ack', '')
                                toast.error('File size should be less than 2MB')
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='modal-footer mt-3'>
                        <button
                          type='button'
                          className='btn btn-light-danger'
                          data-bs-dismiss='modal'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-light-success'
                          data-bs-dismiss='modal'
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddExpenseModal
