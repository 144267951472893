import CMSDETAILS from './CMSDETAILS'
import { CMSTABLE } from '../../../_metronic/partials/widgets/tables/CMSTABLE'
import { CmsStationData } from '../js/CMSStationdata'
import CmsPermission from './CmsPermission'
import { useAuth } from '../../modules/auth'

const CMSDATA = () => {
  const { permissions } = useAuth()

  const tabs = [
    {
      title: 'Details',
      permission: 'ViewCmsUser',
      href: '#kt_tab_pane_4',
      content: <CMSDETAILS />,
    },
    {
      title: 'Permission',
      permission: 'ViewPermissions',
      href: '#kt_tab_pane_5',
      content: <CmsPermission />,
    },
    {
      title: 'Station Details',
      permission: 'ViewStations',
      href: '#kt_tab_pane_6',
      content: <CMSTABLE cmsstations={CmsStationData} className="mb-5 mb-xl-8" />,
    },
  ]

  // Filter tabs based on user permissions
  const visibleTabs = tabs.filter((tab) => permissions.includes(tab.permission))

  return (
    <div>
      <div className="card card-custom">
        <div className="card-header d-flex justify-content-center">
          <h3 className="card-title text-black fs-1 fw-bold">CMS - USER DETAILS</h3>
        </div>
        <div className="card-body">
          <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-4 fw-bold">
            {visibleTabs.map((tab, index) => (
              <li className="nav-item" key={index}>
                <a
                  className={`nav-link ${index === 0 ? 'active' : ''}`}
                  data-bs-toggle="tab"
                  href={tab.href}
                >
                  {tab.title}
                </a>
              </li>
            ))}
          </ul>

          <div className="tab-content" id="myTabContent">
            {visibleTabs.map((tab, index) => (
              <div
                className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                id={tab.href.replace('#', '')}
                role="tabpanel"
                key={index}
              >
                {tab.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CMSDATA
