import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Home from '../pages/home/Home'
import Chargepoint from '../pages/Chargepoint'
import Servicerequest from '../pages/Servicerequest'
import OperatorInformation from '../pages/OperatorInformation'
import TabsSlide from '../pages/Components/Tabs'
import StationDetailsHeader from '../pages/Components/StationDetailsHeader'
import TariffDetails from '../pages/Components/TariffDetails'
import TariffDetailsHeader from '../pages/Components/TariffDetailsHeader'
import UserSinglePage from '../pages/UserSinglePage'
import UserDetailsHeader from '../pages/Components/UserDetailsHeader'
import WalletSinglePage from '../pages/WalletSinglePage'
import WalletDetailsHeader from '../pages/Components/WalletDetailsHeader'
import TransactionSinglePage from '../pages/TransactionSinglePage'
import DetailsTransaction from '../pages/Components/DetailsTransaction'
import PendingTransaction from '../pages/PendingTransaction'
import PTransactions from '../pages/Components/PTransactions'
import FailedT from '../pages/FailedT'
import FailedDetails from '../pages/Components/FailedDetails'
import RfidSinglePage from '../pages/RfidSinglePage'
import RfidUsers from '../pages/Components/RfidUsers'
import RfidUserSinglePage from '../pages/RfidUserSinglePage'
import RfidUserD from '../pages/Components/RfidUserD'
import ConsolidateSinglePage from '../pages/ConsolidateSinglePage'
import ConsolidateData from '../pages/Components/ConsolidateData'
import ConsolidateCreated from '../pages/ConsolidateCreated'
import ConsolidateCreate from '../pages/Components/ConsolidateCreate'
import RolesSinglePage from '../pages/RolesSinglePage'
import RolesSingleData from '../pages/Components/RolesSingleData'
import GroupSinglePage from '../pages/GroupSinglePage'
import GroupSingleData from '../pages/Components/GroupSingleData'
import AddChargeStation from '../pages/AddChargeStation'
import AddStationDetails from '../pages/Components/AddStationDetails'
import AddNewTariff from '../pages/AddNewTariff'
import AddTraiff from '../pages/Components/AddTraiff'
import AddNewUser from '../pages/AddNewUser'
import AddUser from '../pages/Components/AddUser'
import AddTag from '../pages/AddTag'
import AddTags from '../pages/Components/AddTags'
import AddConsolidate from '../pages/AddConsolidate'
import AddConsolidates from '../pages/Components/AddConsolidates'
import AddSchedule from '../pages/AddSchedule'
import AddSchedules from '../pages/Components/AddSchedules'
import AddCmsUser from '../pages/AddCmsUser'
import AddCms from '../pages/Components/AddCms'
import CMSsinglepage from '../pages/CMSsinglepage'
import CMSDATA from '../pages/Components/CMSDATA'
import EditTariff from '../pages/Components/EditTraiff'
import ChargePointEdit from '../pages/ChargePointEdit'
import EditStationDetails from '../pages/Components/EditStationDetails'
import WalletEditPage from '../pages/WalletEditPage'
import RfidEdit from '../pages/RfidEdit'
import ConsolidateEdited from '../pages/ConsolidateEdited'
import EditCMS from '../pages/EditCMS'
import EditSchedule from '../pages/EditSchedule'
import GroupEditPage from '../pages/GroupEditPage'
import StationOperatorDetails from '../pages/StationOperatorDetails'
import EditUserDetails from '../pages/EditUserDetails'
import Employees from '../pages/Employees'
import AddEmployee from '../pages/AddEmployee'
import EditEmployee from '../pages/EditEmployee'
import EmployeeDetails from '../pages/Components/EmployeeDetails'
import Amenities from './../../_metronic/partials/widgets/tables/Amenities'
import Dashboard from '../modules/analytics/menu/components/Dashboard'
import {TotalDeviceTransaction} from '../../_metronic/partials/widgets/tables/TotalDeviceTransaction'
import AddChargerToHub from '../pages/Components/AddChargerToHub'
import {AllChargers} from '../../_metronic/partials/widgets/tables/AllChargers'
import EditCharger from '../pages/Components/EditCharger'
import {TotalConnectors} from '../../_metronic/partials/widgets/tables/TotalConnectors'
import VendorErrorCodes from '../../_metronic/partials/widgets/tables/VendorErrorCodes'
import Reports from '../../_metronic/partials/widgets/tables/Reports'
import AdminControll from '../../_metronic/partials/widgets/tables/AdminControll'
import CarBrandTable from '../../_metronic/partials/widgets/tables/CarBrandTable'
import ConnectorTypesTable from '../../_metronic/partials/widgets/tables/ConnectorTypesTable'
import HubDetails from '../pages/Components/HubDetails'
import {useAuth} from '../modules/auth'
import FaultedConnectors from '../modules/Chargestations/Charge/components/FaultedConnectors'
import StateWiseGst from '../modules/Chargestations/Charge/components/StateWiseGst'

const PrivateRoutes = () => {
  const AnalyticsPage = lazy(() => import('../modules/analytics/menu/AnalyticsPage'))
  const ChargestationPage = lazy(() => import('../modules/Chargestations/Charge/ChargestationPage'))
  const EVUsersPage = lazy(() => import('../modules/Evusers/EV/EVUsersPage'))
  const UserManagementPage = lazy(() => import('../modules/UserManagment/User/UserManagementPage'))
  const CommununicationPage = lazy(() => import('../modules/Communication/Task/CommunicationPage'))
  const ReportPage = lazy(() => import('../modules/report/data/ReportPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const FinancialPage = lazy(() => import('../modules/FinancialManagement/FinancialPage'))
  const {permissions} = useAuth()

  let routes = [
    {path: 'auth/*', element: <Navigate to='/dashboard' />, permission: ['all']},
    {path: '/map', element: <Home />, permission: ['GlobalChargingStation']},
    {path: '/Chargepoint', element: <Chargepoint />, permission: ['ViewOperators']},
    {path: '/ChargePointEdit/:pointId', element: <ChargePointEdit />, permission: ['EditCharger']},
    {path: '/EditChargepoint/:pointId', element: <Chargepoint />, permission: ['EditCharger']},
    {path: '/OperatorInformation/:pointId', element: <TabsSlide />, permission: ['ViewCmsUser']},
    {
      path: '/StationOperatorDetails/:pointId',
      element: <StationOperatorDetails />,
      permission: ['ViewStations', 'GlobalChargingStation'],
    },
    {path: '/AddChargeStation', element: <AddChargeStation />, permission: ['CreateStation']},
    {
      path: '/AddChargeStation/:pointId',
      element: <AddStationDetails />,
      permission: ['EditStation'],
    },
    {
      path: '/EditChargeStation/:pointId',
      element: <EditStationDetails />,
      permission: ['EditStation'],
    },
    {path: '/AddNewTariff', element: <AddNewTariff />, permission: ['CreateTariff']},
    {path: '/AddNewTariff/:pointId', element: <AddTraiff />, permission: ['EditTariff']},
    {path: '/AddNewUser', element: <AddNewUser />, permission: ['CreateCmsUser']},
    {path: '/AddNewUser/:pointId', element: <AddUser />, permission: ['CreateCmsUser']},
    {path: '/AddTag', element: <AddTag />, permission: ['CreateRFIDtag']},
    {path: '/AddTag/:pointId', element: <AddTags />, permission: ['EditRFIDtag']},
    {path: '/AddConsolidate', element: <AddConsolidate />, permission: ['ViewSessions']},
    {path: '/AddConsolidate/:pointId', element: <AddConsolidates />, permission: ['EditSessions']},
    {path: '/AddSchedule', element: <AddSchedule />, permission: ['CreateSettlement']},
    {path: '/AddSchedule/:pointId', element: <AddSchedules />, permission: ['EditSettlement']},
    {path: '/AddCmsUser', element: <AddCmsUser />, permission: ['CreateCmsUser']},
    {path: '/AddCmsUser/:pointId', element: <AddCms />, permission: ['EditCmsUser']},
    {path: '/CMSsinglepage/:pointId', element: <CMSDATA />, permission: ['ViewCmsUser']},
    {path: '/charger/:pointId', element: <StationDetailsHeader />, permission: ['ViewCharger']},
    {
      path: '/TariffSinglePage',
      element: <TariffDetails />,
      permission: ['ViewTariff', 'GlobalTariff'],
    },
    {
      path: '/TariffSinglePage/:pointId',
      element: <TariffDetailsHeader />,
      permission: ['EditTariff'],
    },
    {path: '/TariffEditPage/:pointId', element: <EditTariff />, permission: ['EditTariff']},
    {path: '/UserSinglePage/:pointId', element: <UserDetailsHeader />, permission: ['EditEvUsers']},
    {path: '/EditUserDetails/:pointId', element: <EditUserDetails />, permission: ['EditEvUsers']},
    {path: '/WalletSinglePage', element: <WalletSinglePage />, permission: ['ViewWallet']},
    {
      path: '/WalletSinglePage/:pointId',
      element: <WalletDetailsHeader />,
      permission: ['EditWallet'],
    },
    {path: '/WalletEditPage/:pointId', element: <WalletEditPage />, permission: ['EditWallet']},
    {
      path: '/TransactionSinglePage',
      element: <TransactionSinglePage />,
      permission: ['ViewDeviceTransactions', 'GlobalDeviceTransaction'],
    },
    {
      path: '/TransactionSinglePage/:pointId',
      element: <DetailsTransaction />,
      permission: ['ViewTransactionDetails'],
    },
    {
      path: '/PendingTransaction',
      element: <PendingTransaction />,
      permission: ['ViewTransactions'],
    },
    {
      path: '/PendingTransaction/:pointId',
      element: <PTransactions />,
      permission: ['ViewTransactionDetails'],
    },
    {path: '/FailedT', element: <FailedT />, permission: ['ViewTransactions']},
    {path: '/FailedT/:pointId', element: <FailedDetails />, permission: ['ViewTransactionDetails']},
    {path: '/RfidSinglePage', element: <RfidSinglePage />, permission: ['ViewRFIDtags']},
    {path: '/RfidSinglePage/:pointId', element: <RfidUsers />, permission: ['EditRFIDtag']},
    {path: '/RfidUserSinglePage', element: <RfidUserSinglePage />, permission: ['ViewRFIDtags']},
    {path: '/RfidUserSinglePage/:pointId', element: <RfidUserD />, permission: ['EditRFIDtag']},
    {path: '/RfidEdit/:pointId', element: <RfidEdit />, permission: ['EditRFIDtag']},
    {
      path: '/ConsolidateSinglePage',
      element: <ConsolidateSinglePage />,
      permission: ['ViewSessions'],
    },
    {
      path: '/ConsolidateSinglePage/:pointId',
      element: <ConsolidateData />,
      permission: ['EditSessions'],
    },
    {path: '/ConsolidateCreated', element: <ConsolidateCreated />, permission: ['ViewSessions']},
    {
      path: '/ConsolidateCreated/:pointId',
      element: <ConsolidateCreate />,
      permission: ['EditSessions'],
    },
    {
      path: '/ConsolidateEdited/:pointId',
      element: <ConsolidateEdited />,
      permission: ['EditSessions'],
    },
    {path: '/EditCMS/:pointId', element: <EditCMS />, permission: ['EditCmsUser']},
    {
      path: '/RolesSinglePage/:pointId',
      element: <RolesSingleData />,
      permission: ['ViewPermissions'],
    },
    {path: '/GroupSinglePage/:pointId', element: <GroupSingleData />, permission: ['ViewRoles']},
    {path: '/GroupEditPage/:pointId', element: <GroupEditPage />, permission: ['EditRole']},
    {path: '/EditSchedule/:pointId', element: <EditSchedule />, permission: ['EditSettlement']},
    {path: '/Servicerequest', element: <Servicerequest />, permission: ['ViewReports']},
    {path: '/Employees', element: <Employees />, permission: ['ViewAmenities']},
    {path: '/AddEmployee', element: <AddEmployee />, permission: ['CreateAmenity']},
    {path: '/EditEmployee/:pointId', element: <EditEmployee />, permission: ['EditAmenity']},
    {path: '/Employee/:pointId', element: <EmployeeDetails />, permission: ['ViewAmenities']},
    {path: '/amenity/amenities', element: <Amenities />, permission: ['ViewAmenities']},
    {path: '/dashboard', element: <Dashboard />, permission: ['ViewDashboard']},
    {
      path: '/transactions',
      element: <TotalDeviceTransaction />,
      permission: ['ViewDeviceTransactions', 'GlobalDeviceTransaction'],
    },
    {path: '/total-connectors', element: <TotalConnectors />, permission: ['ViewChargingSession']},
    {path: '/error-codes', element: <VendorErrorCodes />, permission: ['ViewErrorCode']},
    {path: '/all-reports', element: <Reports />, permission: ['ViewReports']},
    {path: '/admin-controll', element: <AdminControll />, permission: ['ViewAdminOptions']},
    {
      path: '/add-charger-station/:stationId',
      element: <AddChargerToHub />,
      permission: ['CreateCharger'],
    },
    {path: '/edit-charger/:chargerId', element: <EditCharger />, permission: ['EditCharger']},
    {
      path: '/station/:pointId',
      element: <AllChargers className='' />,
      permission: ['ViewStations', 'GlobalChargingStation'],
    },
    {
      path: '/station-details/:pointId',
      element: <HubDetails />,
      permission: ['ViewStations', 'GlobalChargingStation'],
    },
    {
      path: 'crafted/pages/profile/*',
      element: (
        <SuspensedView>
          <ProfilePage />
        </SuspensedView>
      ),
      permission: ['ViewAdminOptions'],
    },
    {
      path: 'FinancialManagement/*',
      element: (
        <SuspensedView>
          <FinancialPage />
        </SuspensedView>
      ),
      permission: ['ViewRevenue'],
    },
    {
      path: 'apps/menu/*',
      element: (
        <SuspensedView>
          <AnalyticsPage />
        </SuspensedView>
      ),
      permission: ['ViewRevenue'],
    },
    {
      path: 'ChargeStations/Charge/*',
      element: (
        <SuspensedView>
          <ChargestationPage />
        </SuspensedView>
      ),
      permission: ['ViewStations', 'GlobalChargingStation'],
    },
    {
      path: 'Evusers/EV/*',
      element: (
        <SuspensedView>
          <EVUsersPage />
        </SuspensedView>
      ),
      permission: ['ViewEvUsers'],
      isnestedRoute: true,
      allpermissions: ['ViewEvUsers', 'ViewTransactions', 'ViewWallet', 'ViewRFIDtags'],
    },
    {
      path: 'report/data/*',
      element: (
        <SuspensedView>
          <ReportPage />
        </SuspensedView>
      ),
      permission: ['ViewReports'],
    },
    {
      path: 'UserManagment/User/*',
      element: (
        <SuspensedView>
          <UserManagementPage />
        </SuspensedView>
      ),
      permission: ['ViewCmsUser'],
      isnestedRoute: true,
      allpermissions: ['ViewPermissions', 'ViewRoles'],
    },
    {
      path: 'Communication/Task/*',
      element: (
        <SuspensedView>
          <CommununicationPage />
        </SuspensedView>
      ),
      permission: ['ViewAdminOptions'],
    },
    {
      path: 'crafted/account/*',
      element: (
        <SuspensedView>
          <AccountPage />
        </SuspensedView>
      ),
      permission: ['ViewAdminOptions'],
    },
    {path: '/car/brand', element: <CarBrandTable />, permission: ['ViewCarBrand']},
    {path: '/connector/type', element: <ConnectorTypesTable />, permission: ['ViewConnectorType']},
    {path: '/faulted/connector', element: <FaultedConnectors />, permission: ['ViewCharger']},
    {path: '/state-wise-gst', element: <StateWiseGst />, permission: ['ViewErrorCode']},
  ]

  console.log(permissions, 'permissions at privateroutes')
  const filtered = permissions
    ? routes.filter((route) => {
        if (route.permission.includes('all')) {
          return true
        }
        if (route.permission.some((perm) => permissions.includes(perm))) {
          return true
        }

        if (route.isnestedRoute && route.allpermissions) {
          return route.allpermissions.some((perm) => permissions.includes(perm))
        }

        return false
      })
    : []

  return (
    <Routes>
      <Route path='auth/*' element={<Navigate to='/dashboard' />} />
      <Route element={<MasterLayout />}>
        {filtered.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        <Route path='/dashboard' element={<Dashboard />} />
      </Route>
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
