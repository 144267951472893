import {ErrorMessage, Field, Form, Formik} from 'formik'
import {KTSVG} from '../../helpers'
import {DatePicker} from 'antd'
import {useEffect, useState} from 'react'
import {getRequest} from '../../../app/pages/Helpers/Requests'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'

const {RangePicker} = DatePicker

const TransactionModal = () => {
  const [hubs, setHubs] = useState<any>([])
  const [dates, setDates] = useState<[any, any] | null>(null)
  const [charges, setCharges] = useState<any>([])
  const [hubId, setHubId] = useState<any>(null)

  const initialValues = {
    hubId: '',
    chargerId: '',
    startDate: '',
    endDate: '',
    gst: 'all',
    userId: '',
  }

  const convertJsonToExcel = async (trans: any, startDate: any, endDate: any) => {
    let json = trans?.map((item: any) => {
      const timeDifference =
        new Date(item?.updatedAt).getTime() - new Date(item?.createdAt)?.getTime()
      const secondsDifference = timeDifference / 1000
      return {
        TRANSACTIONID: item?.transactionId,
        STATIONID: item?.stationId,
        CONNECTORID: item?.connectorId,
        NAME: `${item?.user?.firstName} ${item.user?.lastName}`, // Concatenate first and last name
        MOBILENUMBER: item?.user?.mobileNumber, // Use mobileNumber from user
        EMAIL: item?.user?.email,
        GSTNUM: item?.user?.gstNum || '', // Use gstNum from user (if present)
        STARTFROM: item?.platform,
        STOPFROM: item?.stopFrom ? item?.stopFrom : item?.reason == 'Local' ? 'RFID' : item?.reason,
        STARTDATE: item?.startDate,
        STOPDATE: item?.stopDate,
        MACID: item?.macId || '',
        'STARTSOC(%)': item?.startSoc,
        'STOPSOC(%)': item?.stopSoc,
        'CHARGINGDURATION(min)': (secondsDifference / 60).toFixed(2),
        STARTMETERVALUE: item?.startMeterValue,
        STOPMETERVALUE: item?.stopMeterValue, // Directly using stopMeterValue from item
        'ENERGY_CONSUMED(Kw)': parseFloat((item?.total_wh / 1000).toFixed(2)),
        AMOUNT: parseFloat(item?.amount?.toFixed(2)),
        GST: parseFloat(item?.gst?.toFixed(2)),
        TOTAL: parseFloat(item?.price?.toFixed(3)),
        REASON: item?.reason,
      }
    })

    const ws = XLSX.utils.json_to_sheet(json)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })
    let filename = `${new Date(startDate).toDateString()}-to-${new Date(endDate).toDateString()}`
    saveAs(data, `${filename}.xlsx`)
  }

  const handleSubmit = async (values: any, {resetForm}) => {
    const updatedValues = {
      ...values,
      startDate: new Date(values.startDate),
      endDate: new Date(values.endDate),
    }

    console.log('formValues', updatedValues)
    const {startDate, endDate, hubId, chargerId, gst, userId} = updatedValues
    try {
      const res = await getRequest(
        `/device-transaction/all/datewise?startDate=${startDate}&endDate=${endDate}&gst=${gst?.toLowerCase()}&hubId=${hubId}&chargerId=${chargerId}&userId=${userId}`
      )

      if (res?.data?.data) {
        convertJsonToExcel(res?.data?.data, startDate, endDate)
        resetForm()
        setDates([null, null])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllHubs = async () => {
    try {
      const res = await getRequest('/hub')

      if (res?.data) {
        setHubs(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllCharges = async () => {
    try {
      const res = await getRequest(`/hub/${hubId}?include=chargers`)
      if (res?.data) {
        setCharges(res.data?.data?.hub?.chargers)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllHubs()
  }, [])

  useEffect(() => {
    getAllCharges()
  }, [hubId])

  return (
    <div className='modal fade' tabIndex={-1} id='transactionmodal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Device Transactions</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({setFieldValue, values}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Select Hub</label>
                      <Field
                        as='select'
                        name='hubId'
                        className='form-control'
                        onChange={(e: any) => {
                          setHubId(e.target.value)
                          setFieldValue('hubId', e.target.value)
                        }}
                      >
                        <option value=''>Select Hub</option>
                        {hubs?.map((hub: any) => {
                          return (
                            <option key={hub?.id} value={hub?.id}>
                              {hub?.name}
                            </option>
                          )
                        })}
                      </Field>
                      <ErrorMessage name='carType' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Select Charger</label>
                      <Field as='select' name='chargerId' className='form-control'>
                        <option value=''>Select Charger</option>
                        {charges?.map((hub: any) => {
                          return (
                            <option key={hub?.id} value={hub?.id}>
                              {hub?.name}
                            </option>
                          )
                        })}
                      </Field>
                      <ErrorMessage name='carBrand' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Select GST type</label>
                      <Field as='select' name='gst' className='form-control'>
                        <option value='all'>All</option>
                        <option value='with_gst'>With GST</option>
                        <option value='without_gst'>Without GST</option>
                      </Field>
                      <ErrorMessage name='carBrand' component='div' className='text-danger' />
                    </div>

                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>UserId</label>
                      <Field
                        type='text'
                        name='userId'
                        className='form-control'
                        onChange={(e: any) => {
                          setFieldValue('userId', e.target.value)
                        }}
                      />
                      <ErrorMessage name='carBrand' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Select Dates</label>
                      <ErrorMessage name='connectorType' component='div' className='text-danger' />
                    </div>

                    <div className='col-md-12 mt-3'>
                      <RangePicker
                        showTime={true}
                        value={dates}
                        onChange={(dates: any, dateStrings: [string, string]) => {
                          console.log(dates.map((item: any) => new Date(item)))
                          setDates(dates)
                          setFieldValue('startDate', dates[0])
                          setFieldValue('endDate', dates[1])
                        }}
                        format='YYYY-MM-DD HH:mm:ss'
                        style={{flex: '1', height: '40px'}}
                        popupStyle={{zIndex: 1050}}
                        getPopupContainer={(trigger) => trigger.parentElement || document.body}
                      />
                    </div>
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='reset' className='btn btn-light-danger' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='btn btn-light-success'
                      data-bs-dismiss='modal'
                      disabled={!values?.startDate || !values?.endDate}
                    >
                      Download
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionModal
