/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getRequest } from '../Helpers/Requests'

const GeneralDetailsOne = ({ errors, values, setFieldValue, touched }) => {
  const [cms, setCms] = useState<any>([])

  const getAllOperators = async () => {
    try {
      const res = await getRequest(`/cms/users?field=OperatorName,id`)
      if (res && res.data) {
        setCms(res.data.data.CmsUsers)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllOperators()
  }, [])
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-70px w-70px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/car-01.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Station Details
            </a>
          </div>

          <div className='card-toolbar m-0'></div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='operatorId'
                    >
                      <option>Select Operator</option>
                      {cms.map((e, i) => {
                        return (
                          <option key={i} value={e.id}>
                            {e.OperatorName}
                          </option>
                        )
                      })}
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.operatorId && errors.operatorId && errors.operatorId}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      aria-label='Select example'
                      placeholder='Name of Station'
                      name='stationName'
                    />
                    <span className='text-danger fs-5'>
                      {touched.stationName && errors.stationName && errors.stationName}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='mb-10'>
                  <Field
                    as="select"
                    className="form-control"
                    aria-label='Select Type of Station'
                    placeholder='Type of Station'
                    name='type'
                  >
                    <option value={""}>Select Type</option>
                    <option value={'Private'}>Private</option>
                    <option value={'Public'}>Public</option>
                  </Field>
                  <span className='text-danger fs-5'>
                    {touched.type && errors.type && errors.type}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralDetailsOne
