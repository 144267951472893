import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {getRequest, postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  vendorId: Yup.string().required('VendorId is required'),
  data: Yup.object().shape({
    transactionId: Yup.number().required('Transaction Id is required'),
    parameter: Yup.string().required('Parameter is required'),
    value: Yup.number().required('Parameter value is required'),
  }),
})

function TestDataTransfer({stationId}) {
  const initialValues = {
    vendorId: '',
    messageId: '',
    data: {
      transactionId: 0,
      parameter: 'Stop_Energy',
      value: 0,
    },
  }
  const [specifications, setSpecifications] = useState([])
  const getAllStationSpecifications = async () => {
    try {
      const res = await getRequest('/device-specification')
      console.log(res)
      if (res.status === 200) {
        setSpecifications(res.data.data)
        console.log(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllStationSpecifications()
  }, [])
  const handleSubmit = async (values, {resetForm}) => {
    console.log('getting in data transfer handle submit')
    try {
      const res = await postRequest('/ocpp/remote/data-transfer-test/' + stationId, values)
      console.log(res)
      if (res && res.data) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='test_data_transfer'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Data Transfer(Test Phase)</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Select VendorId</label>
                        <Field as='select' name='vendorId' className='form-control'>
                          <option value=' '>Select Vendor</option>
                          {specifications.map((item) => {
                            return <option value={item.vendorName}>{item.vendorName}</option>
                          })}
                        </Field>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Provide Message</label>
                        <Field
                          name='messageId'
                          className='form-control'
                          placeholder='MessageId(Optional)'
                        />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>TransactionId</label>
                        <Field
                          name='data[transactionId]'
                          className='form-control'
                          placeholder='Enter the Text'
                        />
                        <label className='form-label'>Parameter</label>
                        <Field
                          as='select'
                          name='data[parameter]'
                          className='form-control'
                          placeholder='Enter the Text'
                        >
                          <option value={''}>Select paramter</option>
                          <option value='Stop_SoC'>Stop SoC</option>
                          <option value='Stop_Time'>Stop Time</option>
                          <option value='Stop_Energy'>Stop Energy</option>
                        </Field>
                        <label className='form-label'>Paramter Value</label>
                        <Field
                          name='data[value]'
                          className='form-control'
                          placeholder='Parameter Value'
                        />
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestDataTransfer
