import React from 'react'
import {Formik, Form, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

function CustomObject({stationId}) {
  const initialValues = {
    obj: '',
  }

  const handleSubmit = async (values, {resetForm}) => {
    try {
      const res = await postRequest('/ocpp/remote/post-request/' + stationId, values)
      console.log(res)
      if (res.status === 200) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='custom_request'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Post Custom Charging Profile</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Custom Object</label>
                        <Field as='textarea' name='obj' className='form-control' rows='10' />
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomObject
