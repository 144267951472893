import React, {useEffect, useState} from 'react'
import {getRequest} from '../../../../pages/Helpers/Requests'
import UpdateGST from '../../../../../_metronic/partials/modals/UpdateStateGst'

const stateOptions = [
  {value: 'AN', label: 'Andaman and Nicobar Islands'},
  {value: 'AP', label: 'Andhra Pradesh'},
  {value: 'AR', label: 'Arunachal Pradesh'},
  {value: 'AS', label: 'Assam'},
  {value: 'BR', label: 'Bihar'},
  {value: 'CH', label: 'Chandigarh'},
  {value: 'CT', label: 'Chhattisgarh'},
  {value: 'DL', label: 'Delhi'},
  {value: 'GA', label: 'Goa'},
  {value: 'GJ', label: 'Gujarat'},
  {value: 'HP', label: 'Himachal Pradesh'},
  {value: 'HR', label: 'Haryana'},
  {value: 'JH', label: 'Jharkhand'},
  {value: 'JK', label: 'Jammu and Kashmir'},
  {value: 'KA', label: 'Karnataka'},
  {value: 'KL', label: 'Kerala'},
  {value: 'LA', label: 'Ladakh'},
  {value: 'LD', label: 'Lakshadweep'},
  {value: 'MH', label: 'Maharashtra'},
  {value: 'MN', label: 'Manipur'},
  {value: 'ML', label: 'Meghalaya'},
  {value: 'MZ', label: 'Mizoram'},
  {value: 'NL', label: 'Nagaland'},
  {value: 'OD', label: 'Odisha'},
  {value: 'PB', label: 'Punjab'},
  {value: 'PY', label: 'Puducherry'},
  {value: 'RJ', label: 'Rajasthan'},
  {value: 'SK', label: 'Sikkim'},
  {value: 'TN', label: 'Tamil Nadu'},
  {value: 'TG', label: 'Telangana'},
  {value: 'TR', label: 'Tripura'},
  {value: 'UP', label: 'Uttar Pradesh'},
  {value: 'UK', label: 'Uttarakhand'},
  {value: 'WB', label: 'West Bengal'},
]
export default function StateWiseGst() {
  const [StateWiseGst, setStateWiseGst] = useState<any>([])
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const getStateWiseGst = async () => {
    try {
      const res = await getRequest('/state-gst')
      console.log(res)
      if (res && res.data) {
        setStateWiseGst(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const openEditModal = (item: any) => {
    setSelectedItem(item)
  }

  useEffect(() => {
    getStateWiseGst()
  }, [])
  return (
    <div>
      <div className={`card me-2 `}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>State Wise GST</h3>
          <div className='card-toolbar d-flex gap-5'></div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-2'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Sl No.</th>
                  <th className='min-w-210px'>State</th>
                  <th className='min-w-210px'>GST No</th>
                  <th className='min-w-210px'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {StateWiseGst?.map((item: any, index: number) => {
                  return (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>
                        <div className='text-dark fw-bold fs-6'>{item?.station?.hub?.hubId}</div>
                        <span>
                          {stateOptions.find((it: any) => {
                            return it?.value == item?.state
                          })?.label || item.state}
                        </span>
                      </td>
                      <td>
                        <div className='text-dark fw-bold fs-6'>{item?.station?.stationId}</div>
                        <span>{item?.gstNum || 'Not Added'}</span>
                      </td>
                      <td>
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                          data-bs-toggle='modal'
                          data-bs-target='#edit_gst'
                          onClick={() => openEditModal(item)}
                        >
                          <i className='ki-duotone ki-pencil fs-3'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          <UpdateGST selectedItem={selectedItem} fetchData={getStateWiseGst} />
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}
