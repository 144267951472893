/* eslint-disable */

import React, {useEffect, useState} from 'react'
import CMSRole from './CMSRole'
import CMSAssigned from './CMSAssigned'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {getRequest} from '../Helpers/Requests'

const CmsMap = () => {
  const [AvailableRoles, setAvailableRoles] = useState([])
  const [assignedRoles, setAssignedRoles] = useState([])
  let {pointId} = useParams()
  let getAllPermissions = async () => {
    try {
      const res = await getRequest(`/permissions/user/${pointId}`)
      if (res && res.data) {
        console.log(res.data)
        setAvailableRoles(res.data.data.availablePermissions)
        setAssignedRoles(res.data.data.assignedPermissions)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  console.log("assignedPermissions", AvailableRoles);
console.log("assignedPermissions", assignedRoles);
  useEffect(() => {
    getAllPermissions()
  }, [])
  return (
    <div>
      <div className='row g-5 mt-10'>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header d-flex justify-content-center bg-success'>
              <h3 className='card-title text-white'>Available Permissions</h3>
            </div>
            <div className='card-body card-scroll h-400px m-0 p-0 w-Full'>
              <CMSRole AvailableRoles={AvailableRoles} getData={getAllPermissions} />
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <div className='card card-custom card-stretch shadow mb-5'>
            <div className='card-header d-flex justify-content-center bg-success'>
              <h3 className='card-title text-white'>Assigned Permissions</h3>
            </div>
            <div className='card-body card-scroll h-400px m-0 p-0 w-Full'>
              <CMSAssigned assignedRoles={assignedRoles} getData={getAllPermissions} />
            </div>
          </div>
        </div>
        {/* <div className='d-flex justify-content-end'>
          <a href='#' className='btn btn-light-success'>
            Submit
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default CmsMap
