/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import Upload from '../../UI/Upload'

const GeneralDetailsThree = ({errors, setFieldValue, values,touched}) => {
  const {image: initialValuesImage} = values

  const [image, setImage] = useState(initialValuesImage)
  const [selectedImageUrl, setSelectedImageUrl] = useState('')

  const ImageChangeHandler = (e) => {
    const selectedImage = e.target.files[0]
    const imageUrl = URL.createObjectURL(selectedImage)
    setFieldValue('image', selectedImage)
    setImage(selectedImage.name)
    setSelectedImageUrl(imageUrl)
  }

  const imagesetter = () => {
    setImage('')
    setSelectedImageUrl('')
    setFieldValue('image', '')
  }

  useEffect(() => {
    setImage(initialValuesImage)
  }, [initialValuesImage])

  return (
    <div>
      <div className='card card-custom mt-15 mb-10'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-60px w-60px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/Station.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>
            <span className='fs-1 fw-bold text-dark m-0'>Station Images</span>{' '}
            {/* Changed <a> to <span> */}
          </div>

          <div className='card-toolbar m-0'>
            
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='mb-10'>
                <Upload
                  onImageChange={ImageChangeHandler}
                  image={selectedImageUrl}
                  imagesetter={imagesetter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralDetailsThree
